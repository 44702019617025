import { useEffect, useState } from "react";
import { Col, Modal, Card, Tag, Button, Tabs } from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import DiscoveryResponseField from "../DiscoveryResponseField/DiscoveryResponseField";
import { useGlobalContext } from "../../App";
import { updateCase } from "../../actions/cases";
import List from "../DiscoveryResponseField/List/List";
import UploadQuestions from "./UploadQuestions/UploadQuestions";

const EditQuestions = () => {
  const { list, setList } = useGlobalContext();
  const { id, document } = useParams();
  const [caseData, setCaseData] = useState("");
  const [parseDocument, setDocument] = useState("");
  const dispatch = useDispatch();

  const currentCase = useSelector((state) =>
    id ? state.cases.find((item) => item._id === id) : null
  );

  useEffect(() => {
    if (currentCase) {
      setCaseData(currentCase)

      console.log("currentCase", currentCase);
      currentCase.documents.map((item) => {
        if (item.documentType === document) {
          setList(item.questions);
        }
      });

    };
  }, [currentCase]);

  const { matterNumber, caseName } = caseData;

  const success = () => {
    Modal.success({
      content: `Questions Updated in ${document.toUpperCase()}`,
    });
  };

  const updateQuestions = () => {
    const editedDocuments = currentCase.documents.map((item) => {
      if (item.documentType === document) {
        return { documentType: document, questions: list, _id: item._id };
      }
      return item;
    });
    const caseInfo = { ...currentCase, documents: editedDocuments };

    console.log("caseInfo", caseInfo);
    dispatch(updateCase(id, caseInfo));

    success();
    setList([]);
  };

  const addQuestion = () => {
    if (parseDocument != "") {
      const question = {
        id: uuidv4(),
        info: parseDocument.trim(),
        response: "",
        isAnswered: false,
      };
      setList([...list, question]);
      setDocument("");
    } else {

      Modal.warning({
        content: `Please Enter Question.`,
      });
    }
  };

  const tabs = [
    {
      key: '1',
      label: `Edit Questions`,
      children:
        <>
          <DiscoveryResponseField
            document={parseDocument}
            setDocument={setDocument}
          />
          <div style={{ marginTop: 12, display: 'flex' }}>
            <Button onClick={addQuestion} style={{ padding: '16px', marginRight: list?.length > 0 ? '16px' : '0px' }} >Add Question</Button>
            {list?.length > 0 && <Button onClick={() => setList(() => [])} style={{ padding: '16px' }} >Clear Questions</Button>}
          </div>
        </>
      ,
    },
  ];

  return (
    <>
      <div style={{ paddingBottom: '4px' }}><Link to={`/dashboard/${id}`}>Back To Case</Link></div>
      <Card hoverable>
        <Col>
          <PageHeader
            title={`Case #${matterNumber}`}
            subTitle={caseName}
            style={{ backgroundColor: "white", padding: 0 }}
            extra={<Tag color='green'>{document.toUpperCase()}</Tag>}
          />
          <Tabs style={{ paddingTop: '1rem' }} defaultActiveKey="1" items={tabs} />
          {list?.length > 0 && (
            <>
              <div>
                <List questions={list} />
              </div>
            </>
          )}
          {list?.length > 0 &&
            <div style={{ marginTop: 12, width: 'fit-content' }}>
              <Button onClick={updateQuestions} style={{ padding: '16px' }}>Update Questions in Document</Button>
            </div>
          }
        </Col>
      </Card>
    </>
  );
};

export default EditQuestions;

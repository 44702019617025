import { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Table, Tag, Space, Popconfirm, Button, Card } from "antd";
// import ReactHtmlParser from 'html-react-parser';
import ReactHtmlParser from 'html-react-parser';
import { useSelector } from 'react-redux';
import * as accessControlHelper from '../../utils/accessControlHelper';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'
const DisplayResponse = () => {
  const [responses, setResponses] = useState([]);
  const user = useSelector((state) => state.auth);

  const can_edit = accessControlHelper.VerifyAccess(accessControlHelper.EDIT_RESPONSES);
  const can_delete = accessControlHelper.VerifyAccess(accessControlHelper.DELETE_RESPONSES);

  let userId = '';
  let userAccess = '';
  if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_ALL_RESPONSES)) {
    userId = user?.user?.role;
    userAccess = "All";
  } else if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_OWN_AND_SUBORDINATES_RESPONSES)) {
    userId = user?.user?.id;
    userAccess = "Own-and-subordinates";
  } else {
    userId = user?.user?.id;
    userAccess = "Own";
  }
  // if (user?.user?.type == 'admin-cas') {
  //   userId = user?.user?.id
  // } else if (user?.user?.type == 'admin-sup') {
  //   userId = user?.user?.type
  // }
  // else if (user?.user?.type == 'admin-att') {

  //   userId = user?.user?.id
  // }
  // else {
  //   userId = user?.user?.user_id
  // }
  // console.log('user_id  ', userId);

  const deleteResponse = (id) => {
    axios.delete(`${BASE_URL}/api/responses/${id}`)
      .then(() =>
        axios.get(`/api/responses/${userId}`)
          .then((res) => setResponses(res.data))
          .catch((error) => console.log(error))
      );
  }

  useEffect(() => {
    axios.get(`${BASE_URL}/api/responses/${userId}/${userAccess}`)
      .then((res) => setResponses(res.data))
      .catch((error) => console.log(error))


  }, [responses.length])



  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <>{text}</>
      ),
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text, record) => (
        <>{ReactHtmlParser(text)}</>
      ),
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "assignTo",
      render: (text, record) => (
        <>{text.map((tex) => {
          return (
            <Tag color="green" key={tex}>
              <Link to={''}>{tex.toUpperCase()}</Link>
            </Tag>
          )
        })}</>
      ),
    },
    {
      title: "Owner",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>{ReactHtmlParser(text)}</>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "_id",
      render: (_id, userId, record) => {

        return (
          <Space size="middle">

            {(can_edit) &&
              <Link to={`/responses/update/${_id}`}>
                <EditOutlined />
              </Link>

            }
            {(can_delete) &&
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteResponse(_id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            }
          </Space>
        );

      },
    },
  ];

  const tableData = responses.map((resp) => {
    const { title, content, assignTo, creator, _id, userId, name } = resp;
    return {
      key: _id,
      title,
      content,
      assignTo,
      creator,
      _id,
      userId,
      name
    };
  });
  return (
    <Card hoverable>
      <div style={{ marginBottom: 20, display: "flex", justifyContent: "flex-end" }}>
        {/* Render button to add new response if user has access */}
        {accessControlHelper.VerifyAccess(accessControlHelper.ADD_RESPONSES) && (
          <Link to={"/responses/new"}>
            <Button type="primary">Add New Response</Button>
          </Link>
        )}
      </div>
        <Table columns={columns} dataSource={tableData} />
    </Card>
  )

}

export default DisplayResponse

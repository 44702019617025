import axios from 'axios';
import  { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Input, Typography, Card, Button, Checkbox } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000';

const UpdateResponse = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [assignTo, setAssignTo] = useState([]);
  const navigate = useNavigate();
  let {id} = useParams();

  const updateResp = (id, title="test", content, assignTo) => {
    console.log("title", title, content, assignTo)
    axios.patch(`${BASE_URL}/api/responses/${id}`, {title, content, assignTo})
    .then((res) => console.log("res-",res))
    .catch((err) => console.log("err-",err));
  }

  useEffect(() => {
    fetch(`${BASE_URL}/api/responses/one/${id}`,{
            method: "GET",
    })
    .then((response) => response.json())
    .then((result) => {
          console.log("resultt",result);
        setAssignTo(result?.assignTo);
        setTitle(result?.title);
        setContent(result?.content);
      })
    .catch((error) => console.log("error", error));
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
      updateResp(id, title, content, assignTo);
      navigate('/responses')
  }
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setContent(data);
  }

  const valueCheck = (val) => {
    let res = false
    return res
  }
  
  const onChange= (e) => {
    const { value, checked} = e.target;

    if(checked) {
      setAssignTo([...assignTo, value])
    }
    else {
      setAssignTo(
        assignTo?.filter((e)=> e !== value)
      )
    }
  }
  return (
    <Card hoverable>
      <Form>
        <Typography.Title>
          Update Response
        </Typography.Title>
        <Form.Item label="Title">
          <Input
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            name="title"
            type="text"
            style={{ width: 500 }}
          />
        </Form.Item>
        <Form.Item label="Content">
          <CKEditor
            editor={ClassicEditor}
            name="content"
            data={content}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item name="assignTo" label="Assign To">
          <Checkbox checked={assignTo?.includes('RFA')} onChange={onChange} value="RFA">RFA</Checkbox>
          <Checkbox checked={assignTo?.includes('RFP')} onChange={onChange} value="RFP">RFP</Checkbox>
          <Checkbox checked={assignTo?.includes('ROGGS')} onChange={onChange} value="ROGGS" >ROGGS</Checkbox>
        </Form.Item>
        <Form.Item name="add-btn" className="submit-button">
          <Button
            type="primary"
            onClick={handleSubmit}
          >
            Update Response
          </Button>
        </Form.Item>
       </Form>
    </Card>
  )
}

export default UpdateResponse

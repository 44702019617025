import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import classes from './DocumentPreview.module.css'
import Documents from "../../components/Document/Documents";
import CaseHeader from "../../components/CaseHeader/CaseHeader";
import html2pdf from 'html2pdf.js'

const DocumentPreview = () => {
  const [renderKey, setRenderKey] = useState(Math.random())
  const { id, document } = useParams();
  const [questions, setQuestions] = useState([]);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [downloadingPDFWithoutQA, setDownloadingPDFWithoutQA] = useState(false);

  const [downloadingWordDoc, setDownloadingWordDoc] = useState(false);
  const [caseData, setCaseData] = useState("");
  const htmlContentRef = useRef(null);
  const htmlContentRefWithoutQA = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const currentCase = useSelector((state) =>
    id ? state.cases.find((item) => item._id === id) : null
  );

  // const handleDownloadWordDoc = async () => {
  //   setDownloadingWordDoc(true);
  //   console.log(htmlContentRef.current)

  //   const { arrayBuffer } = await mammoth.convert({ html: htmlContentRef.current });

  //   const blob = new Blob([arrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
  //   saveAs(blob, 'download.docx');
  //   setDownloadingWordDoc(false);
  // }

  const downloadPdf = async () => {
    setDownloadingPDF(true);
    setRenderKey(Math.random());
    try {
      await html2pdf()
        .set({
          margin: 0.5,
          filename: 'download.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(htmlContentRef.current)
        .save();
    } catch (error) {
      console.log('err downloading PDF: ', error);
    }
    setDownloadingPDF(false);
  };


  const downloadPdfWithoutQA = async () => {
    setDownloadingPDFWithoutQA(true);
    setRenderKey(Math.random());
    try {
      await html2pdf()
        .set({
          margin: 0.5,
          filename: 'download-C.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        })
        .from(htmlContentRefWithoutQA.current)
        .save();
    } catch (error) {
      console.log('err downloading PDF: ', error);
    }
    setDownloadingPDFWithoutQA(false);
  };

  useEffect(() => {
    if (currentCase) {
      const currentDocument = currentCase.documents.filter(
        (item) => item.documentType === document
      )[0];
      setCaseData(currentCase);
      console.log('currentDocument.questions',currentDocument.questions);
      setQuestions(currentDocument.questions);
    }
  }, [currentCase, document]);

  const { matterNumber, caseName } = caseData;

  return (
    <div className={`${classes.previewContainer} `}>
      <div style={{ marginBottom: '0.5rem' }}><Link to={`/dashboard/${id}`}> Back To Case </Link></div>
      <div className={classes.documentCard}>
        <CaseHeader
          caseName={caseName}
          document={document}
          matterNumber={matterNumber}
          downloadPdf={downloadPdf}
          downloadingPDF={downloadingPDF}
          downloadPdfWithoutQA={downloadPdfWithoutQA}
          downloadingPDFWithoutQA={downloadingPDFWithoutQA}
          downloadingWordDoc={downloadingWordDoc}
          defendant={caseData?.defendant}
        />
      </div>

      <div className={`${classes.documentCard} ${classes.pdf} slide-up-fade-in`}>
        <div ref={htmlContentRef}>
          <Documents key={renderKey} user={user} document={document} list={questions} caseData={caseData} />
        </div>

        <div style={{ display: "none" }}>
          <div ref={htmlContentRefWithoutQA}>
            <Documents key={renderKey} user={user} document={document} list={[]} caseData={caseData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocumentPreview;

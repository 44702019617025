import { useState, useEffect } from "react";
import { Button, List, Space, Row, Col, Modal, Select, Card, Typography } from "antd";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { PageHeader } from '@ant-design/pro-layout';

import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";
import moment from "moment";

import { TableWrapper } from "./style";
import { updateDocsNames } from "../../actions/cases";
import * as accessControlHelper from '../../utils/accessControlHelper';

const Case = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [caseData, setCaseData] = useState("");
  const [serveiceTableDocs, setServiceTableDocs] = useState([]);
  const user = useSelector((state) => state.auth);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleOne, setIsModalVisibleOne] = useState(false);

  const EDIT_CASES = accessControlHelper.VerifyAccess(accessControlHelper.EDIT_CASES);
  const ADD_QUESTIONS_IN_DOCUMENTS = accessControlHelper.VerifyAccess(accessControlHelper.ADD_QUESTIONS_IN_DOCUMENTS);
  const VIEW_CASE_DOCUMENTS = accessControlHelper.VerifyAccess(accessControlHelper.VIEW_CASE_DOCUMENTS);
  const EDIT_QUESTIONS_IN_DOCUMENTS = accessControlHelper.VerifyAccess(accessControlHelper.EDIT_QUESTIONS_IN_DOCUMENTS);


  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showModalOne = () => {
    setIsModalVisibleOne(true);
  };

  const handleOkOne = (e) => {
    // setIsModalVisibleOne(false);
    e.preventDefault();
    console.log("docs: ", serveiceTableDocs);

    if (serveiceTableDocs.length > 0) {
      const docsNames = {
        serveiceTableDocs: serveiceTableDocs,
      };
      dispatch(updateDocsNames(currentCase._id, docsNames));
      // Modal.success({
      //   content: "Document name(s) updated successfully",
      // });
    }
  };

  const handleCancelOne = () => {
    setIsModalVisibleOne(false);
  };

  const { Option } = Select;

  const options = [
    { label: "RFA", value: "rfa" },
    { label: "RFP", value: "rfp" },
    { label: "ROGGS", value: "roggs" },
  ];

  const children = [];
  for (let i = 0; i < options.length; i++) {
    children.push(
      <Option key={options[i].label} value={options[i].value}>
        {options[i].label}
      </Option>
    );
  }

  const currentCase = useSelector((state) =>
    id ? state.cases.find((item) => item._id === id) : null
  );

  useEffect(() => {
    if (currentCase) setCaseData(currentCase);
  }, [currentCase]);

  const {
    matterNumber,
    court,
    caseName,
    caseNumber,
    plaintiff,
    defendant,
    dueDate,
    documents,
    docsNames,
  } = caseData;

  // console.log("caseData: ", caseData);
  return (
    <Card hoverable>
      {/* <PageHeader
        title={`Case: ${matterNumber}`}
        subTitle={caseName}
        style={{ backgroundColor: "white" }}
      /> */}
      <Typography.Title style={{ marginBottom: 8 }}>
        Case: {matterNumber}
      </Typography.Title>
      <Typography.Text type="secondary">
        {caseName}
      </Typography.Text>
      <List
        style={{ backgroundColor: "white" }}
        itemLayout="vertical"
      >
        {!documents ? (
          <Row>
            <Col>
              <List.Item>No documents added to this discovery</List.Item>
            </Col>
          </Row>
        ) : (
          <>
            <>
              {!EDIT_CASES ? null : (
                <div
                  style={{
                    display: "flex",
                    justifyItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignSelf: "center" }}>
                    <h3>Service Table: </h3>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-between",
                      margin: 'auto 0 auto 5px',
                      marginLeft: 20,
                    }}
                  >
                    <div>
                      <Button onClick={showModalOne} type="primary">
                        Edit
                      </Button>
                      <Modal
                        title="Edit Service Table"
                        visible={isModalVisibleOne}
                        onOk={(e) => handleOkOne(e)}
                        onCancel={handleCancelOne}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex", alignSelf: "center" }}>
                            <label>Documents:</label>
                          </div>
                          <div style={{ marginLeft: 10, width: "100%" }}>
                            <Row>
                              <Select
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                placeholder="Select documents"
                                defaultValue={docsNames}
                                onChange={(e) => setServiceTableDocs(e)}
                              >
                                {children}
                              </Select>
                            </Row>
                          </div>
                        </div>
                      </Modal>
                    </div>
                    <div style={{ marginLeft: 5 }}>
                      <Button onClick={showModal} type="primary">
                        View Case
                      </Button>
                      <Modal
                        title="Service Table"
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                      >
                        <TableWrapper>
                          <tbody>
                            <tr>
                              <th>Case No.</th>
                              <td>{caseNumber}</td>
                            </tr>
                            <tr>
                              <th>Court Identity:</th>
                              <td>{court}</td>
                            </tr>
                            <tr>
                              <th>Initial party (Plaintiff):</th>
                              <td>{plaintiff}</td>
                            </tr>
                            <tr>
                              <th>Initial party (Defendant):</th>
                              <td>{defendant}</td>
                            </tr>
                            <tr>
                              <th>Documents being served:</th>
                              {/* plaintiff resopnse to defendent documents rfa/rfp/roggs */}
                              {/* roggs - open ending question */}
                              <td>
                                {/* {docsNames && docsNames.length > 0 &&
                                  docsNames.length < 2 ? (
                                  docsNames.map((doc) => <span>{doc}</span>)
                                ) : docsNames && docsNames.length > 1 &&
                                  docsNames.length < 3 ? (
                                  <span>
                                    {docsNames[0] + " and " + docsNames[1]}
                                  </span>
                                ) : docsNames && docsNames.length > 2 &&
                                  docsNames.length < 4 ? (
                                  <span>
                                    {docsNames[0] +
                                      ", " +
                                      docsNames[1] +
                                      " and " +
                                      docsNames[2]}
                                  </span>
                                ) : null} */}
                                {documents?.map((doc, i) => {
                                  if (doc.documentType === "rfa") {
                                    return (
                                      <span key={i}>
                                        {`${i + 1}. ${doc.noticeHeading ? doc.noticeHeading : "NOTICE OF SERVICE OF PLAINTIFF'S RESPONSE TO DEFENDANT'S REQUEST FOR ADMISSIONS"}`}
                                        <br />
                                      </span>
                                    );
                                  } else if (doc.documentType === "rfp") {
                                    return (
                                      <span key={i}>
                                        {`${i + 1}. ${doc.noticeHeading ? doc.noticeHeading : "NOTICE OF SERVICE OF PLAINTIFF'S RESPONSE TO DEFENDANT'S REQUEST FOR PRODUCTION"}`}
                                        <br />
                                      </span>
                                    );
                                  } else if (doc.documentType === "roggs") {
                                    return (
                                      <span key={i}>
                                        {`${i + 1}. ${doc.noticeHeading ? doc.noticeHeading : "NOTICE OF SERVICE OF PLAINTIFF'S RESPONSE TO DEFENDANT'S SET OF INTERROGATORIES"}`}
                                        <br />
                                      </span>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}

                              </td>
                            </tr>
                            <tr>
                              <th>Sender's name:</th>
                              <td>{user.user.name}</td>
                            </tr>
                            <tr>
                              <th>Sender's phone number:</th>
                              <td>{user.user.phone}</td>
                            </tr>
                          </tbody>
                        </TableWrapper>
                      </Modal>
                    </div>
                  </div>
                </div>
              )}
            </>
            {documents.map((item, index) => {
              const { documentType, questions } = item;
              const answeredQuestionsCount = questions.filter(
                (item) => item.isAnswered
              ).length;
              const unansweredQuestionsCount = questions.filter(
                (item) => !item.isAnswered
              ).length;

              return (
                <Row key={index}>
                  <Col>
                    <List.Item>
                      {user.user.role === accessControlHelper.CLIENT_ROLE ? null : (
                        <List.Item.Meta
                          title={documentType.toUpperCase()}
                          description={`Due Date: ${moment(dueDate).format(
                            "MMM Do"
                          )}`}
                        />
                      )}
                      <Space size="small">
                        {user.user.role === accessControlHelper.CLIENT_ROLE ? (
                          <Link to={`/edit/${documentType}/${id}`}>
                            <Button style={{ padding: 10 }}>
                              View Questions
                            </Button>
                          </Link>
                        ) : (
                          <Link to={`/edit/${documentType}/${id}`}>
                            <Button style={{ padding: 10 }}>
                              Edit Responses
                            </Button>
                          </Link>
                        )}
                        {ADD_QUESTIONS_IN_DOCUMENTS ? (
                          <Link to={`/add/${documentType}/${id}`}>
                            <Button style={{ padding: 10 }}>
                              Add Questions
                            </Button>
                          </Link>
                        ) : null}
                        {EDIT_QUESTIONS_IN_DOCUMENTS ? (
                          <Link to={`/edit-questions/${documentType}/${id}`}>
                            <Button style={{ padding: 10 }}>
                              Edit Questions
                            </Button>
                          </Link>
                        ) : null}
                        {VIEW_CASE_DOCUMENTS ? (
                          <Link to={`/view/${documentType}/${id}`}>
                            <Button style={{ padding: 10 }}>
                              Preview Document
                            </Button>
                          </Link>
                        ) : null}
                      </Space>
                    </List.Item>
                  </Col>
                  {user.user.role === accessControlHelper.CLIENT_ROLE ? (
                    <Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CloseSquareOutlined
                        style={{ fontSize: "1.2rem", paddingRight: 5 }}
                      />
                      {` ${unansweredQuestionsCount}`}
                      <br />
                      <CheckSquareOutlined
                        style={{
                          fontSize: "1.2rem",
                          paddingLeft: 10,
                          paddingRight: 5,
                        }}
                      />
                      {` ${answeredQuestionsCount}`}
                    </Col>
                  ) : (
                    <Col
                      span={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 70,
                        marginLeft: '1rem'
                      }}
                    >
                      <CloseSquareOutlined
                        style={{ fontSize: "1.2rem", paddingRight: 5 }}
                      />
                      {` ${unansweredQuestionsCount}`}
                      <br />
                      <CheckSquareOutlined
                        style={{
                          fontSize: "1.2rem",
                          paddingLeft: 10,
                          paddingRight: 5,
                        }}
                      />
                      {` ${answeredQuestionsCount}`}
                    </Col>
                  )}
                </Row>
              );
            })}
          </>
        )}
      </List>

      <Link to="/">Back To Home</Link>
    </Card>
  );
};

export default Case;



import CaseList from "../../components/CaseList/CaseList";

const Home = () => {
  return (
    <>
      <CaseList />
    </>
  );
};

export default Home;

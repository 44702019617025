
import { FolderOpenOutlined, PlusSquareOutlined, QuestionOutlined } from "@ant-design/icons";
import { Layout, Menu, Dropdown } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutUser } from "../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as accessControlHelper from "../utils/accessControlHelper";

const { Header, Sider, Footer, Content } = Layout;

const onLogoutClick = (props) => {
  // e.preventDefault();
  props.logoutUser();
};


const BasicLayout = (props) => {
  const path = useLocation();
  const navigate = useNavigate();
  var pathName = JSON.stringify(path.pathname).split("/");
  
  const location = useLocation();
  const current = location.pathname.split('/').slice(1);
  const myPath = current[0];

  const DASHBOARD = accessControlHelper.VerifyAccess(accessControlHelper.DASHBOARD);
  const VIEW_CASES = accessControlHelper.VerifyAccess(accessControlHelper.VIEW_CASES);
  const SHOW_CASES_ON_DASHBOARD = accessControlHelper.VerifyAccess(accessControlHelper.SHOW_CASES_ON_DASHBOARD);
  const ADD_CASES = accessControlHelper.VerifyAccess(accessControlHelper.ADD_CASES);
  const VIEW_USERS = accessControlHelper.VerifyAccess(accessControlHelper.VIEW_USERS);
  const ACCESS_RESPONSES = accessControlHelper.VerifyAccess(accessControlHelper.ACCESS_RESPONSES);
  const ACCESS_OBJECTIONS = accessControlHelper.VerifyAccess(accessControlHelper.ACCESS_OBJECTIONS);
  const ACCESS_CONTROL = accessControlHelper.VerifyAccess(accessControlHelper.ACCESS_CONTROL);

  function capitalizeFirstLetter(str) {

    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  const result = capitalizeFirstLetter(myPath.replace("-", " "))

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="#" onClick={() => navigate('/profile')}>Profile</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="1">
        <Link to={'/login'} onClick={() => onLogoutClick(props)}>
          Logout
        </Link>
      </Menu.Item>
    </Menu>
  );

  if (
    !props.auth.isAuthenticated && !props.auth.user.first_login &&
    pathName[1] !== 'reset-password"' &&
    pathName[1] !== 'login"' &&
    pathName[1] !== "reset-password"
  ) {
    window.location.href = "../login";
  }
  if (!props.auth.isAuthenticated) {
    return (
      <>
        <Layout className="cdtl">
          <Header title="user" className="headerbar" style={{ display: "none" }}></Header>
          <Content style={{ margin: "24px 16px 0" }}>
            <div
              className="site-layout-background"
              style={{ padding: 0, minHeight: "unset", width: 360 }}
            >
              {props.children}

            </div>
          </Content>
          <Footer style={{ display: "none" }}></Footer>
        </Layout>
      </>
    );
  }
  else if (props.auth.isAuthenticated && !props.auth.user.first_login) {
    return (
      <>
        <Layout className="cdtl">
          <Header title="user" className="headerbar" style={{ display: "none" }}></Header>
          <Content style={{ margin: "24px 16px 0" }}>
            <div
              className="site-layout-background"
              style={{ padding: 0, minHeight: "unset", width: 360 }}
            >
              {props.children}

            </div>
          </Content>
          <Footer style={{ display: "none" }}></Footer>
        </Layout>
      </>
    );
  }

  return (

    <>
      <Layout>
        <Sider
          breakpoint="md"
          collapsedWidth="0"
          className="leftmenu"
        >
          {/* <Logo style={{ textAlign: "center" }}>add logo </Logo> */}
          <img src="./img/logonew.png" alt="" />
          <Menu
            theme="dark"
            className="ant-menu-item-selected"
            style={{ marginTop: "0", paddingTop: "0" }}
            mode="inline"
          >
            {(DASHBOARD) &&
              <Menu.Item key="1" icon={<FolderOpenOutlined />}>
                <Link to="/dashboard">Dashboard</Link>
              </Menu.Item>
            }
            {VIEW_CASES && (
              <Menu.Item key="2" icon={<FolderOpenOutlined />}>
                <Link to="/cases">Discovery</Link>
              </Menu.Item>
            )}
            {SHOW_CASES_ON_DASHBOARD && (
              <Menu.Item key="3" icon={<FolderOpenOutlined />}>
                <Link to="/dashboard">Discovery</Link>
              </Menu.Item>
            )}
            {ADD_CASES && (
              <Menu.Item key="4" icon={<PlusSquareOutlined />}>
                <Link to="/new">New Case</Link>
              </Menu.Item>
            )}
            {VIEW_USERS && (
              <Menu.Item key="5" icon={<PlusSquareOutlined />}>
                <Link to="/users">Users</Link>
              </Menu.Item>
            )}
            {ACCESS_RESPONSES && (
              <Menu.Item key="6" icon={<PlusSquareOutlined />}>
                <Link to="/responses">Responses</Link>
              </Menu.Item>
            )}
            {ACCESS_OBJECTIONS && (
              <Menu.Item key="7" icon={<PlusSquareOutlined />}>
                <Link to="/objections">Objections</Link>
              </Menu.Item>
            )}
            {ACCESS_CONTROL && (
              <Menu.Item key="8" icon={<PlusSquareOutlined />}>
                <Link to="/access-control">Access Control</Link>
              </Menu.Item>
            )}
            
            <Menu.Item key="9" icon={<QuestionOutlined />}>
                <Link to="/tech-support">Tech Support</Link>
              </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="fulllayout" style={{ display: "block" }}>
          <Header title="user" className="headerbar" style={{ height: "auto" }}>
            <div className="topheader">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a
                  className="ant-dropdown-link adminmenu"
                  onClick={(e) => e.preventDefault()}
                  style={{ paddingRight: 25, float: "right" }}
                >
                  <img src="./img/admin-ico.png" alt="" />
                  {props.auth.user.name}


                  {/* <span>{props.auth.user.type === "admin-sup" ? ' Super Admin' :
                    props.auth.user.type === "admin-cas" ? ' Law Firm' :
                      props.auth.user.type === "admin-att" ? ' Attorney' : ' Client'} <span className="arrow"></span></span> */}
                  <span>{props.auth.user.role.replace("-", " ")} <span className="arrow"></span></span>
                </a>
              </Dropdown>
            </div>
            <div className="pagebar"><h1>{result}</h1></div>
          </Header>
          <Content style={{ margin: "24px 16px 0" }}>
            <div
              className="site-layout-background main-frame"
              style={{ width: "100%", margin: "30px auto 100px auto" }}
            >
              {props.children}
            </div>
          </Content>
          <Footer style={{ display: "none" }}></Footer>
        </Layout>
      </Layout>
    </>
  );
};

BasicLayout.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  cases: state.cases,
});
export default connect(mapStateToProps, { logoutUser })(BasicLayout);

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Form,
    Input,
    Checkbox,
    Button,
    Typography,
    Card,
    Modal,
    Radio,
    Tooltip
} from "antd";

import { Link } from "react-router-dom";
import { createRole, updateRole } from "../../actions/accessControl";
import { useParams } from "react-router-dom";
import axios from "axios";
import { EditOutlined, QuestionCircleOutlined  } from "@ant-design/icons";
const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

const AddNewRole = () => {

    const dispatch = useDispatch();
    let { id } = useParams();
    const [editMode, setEditMode] = useState(false);
    const [isDefault, setIsDefault] = useState(false);

    const [rawPermissions, setRawPermissions] = useState([]);

    const [formData, setFormData] = useState({
        name: "",
        status: true,
        permissions: [],
    });

    useEffect(() => {
        if (id) {
            axios.get(`${BASE_URL}/api/access-control/role/${id}`).then(
                (response) => {
                    setFormData({ ...response.data.role })
                    setEditMode(true);
                    if (response.data.role.is_default) {
                        setIsDefault(true);
                    }
                }
            )
        }
        axios.get(`${BASE_URL}/api/access-control/permissions`).then(
            (response) => {
                console.log(response)
                setRawPermissions(response.data.permissions)
            }
        )
    }, [])

    const [formError, setFormError] = useState({
        nameErr: "",
        statusErr: "",
        permissionsErr: "",
    });

    const onChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setFormData({ ...formData, permissions: [...formData.permissions, value] })
        }
        else {
            setFormData({ ...formData, permissions: formData.permissions.filter((e) => e !== value) })
        }

        setFormError({
            ...formError,
            permissionsErr: "",
        });
    }

    const handleSubmit = () => {
        if (formData.name === "") {
            setFormError({
                ...formError,
                nameErr: "Name is required.",
            });
        }
        if (formData.permissions.length < 1) {
            setFormError({
                ...formError,
                permissionsErr: "Please Select at least one permission.",
            });
        } else {
            // SUBMIT FORM HERE
            if (!editMode) {
                dispatch(createRole(formData));
            } else {
                dispatch(updateRole(id, formData));
            }
            Modal.success({
                content: "New role created successfully",
            });
            window.location.replace("/access-control");
        }
    };

    return <Card>
        <Typography.Title>
            {editMode ? "Edit" : "New"} Role
        </Typography.Title>
        <Form
            layout="horizontal"
            className="discovery"
        >
            <Form.Item name="name">
                <div className="custom-form-label">Name</div>
                <Input
                    value={formData.name}
                    disabled={isDefault}
                    onClick={() =>
                        setFormError({
                            ...formError,
                            nameErr: "",
                        })
                    }
                    placeholder="Enter Role's Name" onChange={(e) =>
                        setFormData({ ...formData, name: e.target.value })
                    }
                />
                {formError.nameErr ? (
                    <>
                        <div>
                            <p style={{ marginTop: 2, color: "red" }}>
                                {formError.nameErr}
                            </p>
                        </div>
                    </>
                ) : null}
            </Form.Item>

            {/* <Form.Item name="status">
                <div className="custom-form-label">Status</div>
                <Radio.Group
                    optionType="button"
                    name="status"
                    defaultValue={formData?.status ? "true" : "false"}>
                    <Radio.Button value="true"
                        onClick={() => setFormData({ ...formData, status: true })}
                    >Enable</Radio.Button>
                    <Radio.Button value="false"
                        onClick={() => setFormData({ ...formData, status: false })}>Disable</Radio.Button>
                </Radio.Group>
            </Form.Item> */}

            <Form.Item name="permissions">
                <div className="custom-form-label d-flex">Permissions</div>
                {rawPermissions?.map((permission, index) => (

                    <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: '10px' }}>
                        <Checkbox value={permission.slug} checked={formData.permissions?.includes(permission.slug)} onChange={onChange}>{permission.name}</Checkbox>
                        {
                            permission.description &&
                            <Tooltip title={permission.description}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        }
                    </div>
                ))}
                {formError.permissionsErr ? (
                    <>
                        <div>
                            <p style={{ marginTop: 2, color: "red" }}>
                                {formError.permissionsErr}
                            </p>
                        </div>
                    </>
                ) : null}
            </Form.Item>

            <Form.Item name="submit-button">
                <div style={{ marginTop: 10, textAlign: "right", width: "100%" }}>
                    <Button htmlType="submit" type="primary" onClick={() => { handleSubmit() }}>
                        {editMode ? "Update" : "Add New Role"}
                    </Button>
                </div>
            </Form.Item>
        </Form >
    </Card >
}

export default AddNewRole;
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Button,
  Space,
  Typography,
  Modal,
  Radio,
  Tag,
} from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import TheList from "./List";
import { Link } from "react-router-dom";
import { updateCase } from "../../actions/cases";
import AnsweredQuestions from "./AnsweredQuestions";
import classes from './QuestionsList.module.css'
import { Tooltip } from "antd/lib";
import * as accessControlHelper from '../../utils/accessControlHelper';

const QuestionList = () => {
  const { id, document } = useParams();
  const [questions, setQuestions] = useState([]);
  const [caseData, setCaseData] = useState("");
  const [answeredQuestions, setAnsweredQuestions] = useState([]);
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [showAnswered, setShowAnswered] = useState(false);
  const [currentDocumentQ, setCurrentDocumentQ] = useState([]);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const currentCase = useSelector((state) =>
    id ? state.cases.find((item) => item._id === id) : null
  );

  useEffect(() => {
    if (currentCase) {
      console.log(currentCase);
      const currentDocument = currentCase.documents.filter(
        (item) => item.documentType === document
      )[0];

      setCaseData(currentCase);
      setQuestions(currentDocument.questions);
      setCurrentDocumentQ(currentDocument);
      setAnsweredQuestions(
        currentDocument.questions.filter(
          (question) => question.isAnswered === true
        )
      );

      setUnansweredQuestions(
        currentDocument.questions.filter(
          (question) => question.isAnswered === false
        )
      );
    }
  }, [currentCase, document]);

  const { matterNumber, caseName } = caseData;

  const success = () => {
    Modal.success({
      content: `Responses added to ${document.toUpperCase()}`,
    });
  };

  const addResponsesToDatabase = () => {
    const editedDocuments = currentCase.documents.map((item) => {
      if (item.documentType === document) {
        return { documentType: document, questions: questions };
      }
      return item;
    });

    const caseInfo = { ...currentCase, documents: editedDocuments };

    console.log(caseInfo);

    dispatch(updateCase(id, caseInfo));
    success();
    // window.location.reload();
  };

  return (
    <>
      <div style={{ marginBottom: '4px' }}>
        <Link to={`/dashboard/${id}`}>Back To Case</Link>
      </div>
      <div className={classes.pageHeaderContainer}>
        <div>
          <PageHeader
            title={caseName}
            className={classes.pageHeader}
            subTitle={
              <div>
                <span><Tag color='green' key="1">{document.toUpperCase()}</Tag></span>
              </div>
            }
            style={{ backgroundColor: "white", padding: '0rem' }}
            extra={
              <>
                <Radio.Group
                  buttonStyle="solid"
                  style={{ margin: '0' }}
                  optionType="button"
                  defaultValue="unanswered"
                >
                  <Radio.Button
                    value="unanswered"
                    onClick={() => setShowAnswered(false)}
                  >
                    Unanswered
                  </Radio.Button>
                  <Radio.Button
                    value="answered"
                    onClick={() => setShowAnswered(true)}
                  >
                    Answered
                  </Radio.Button>
                </Radio.Group>
              </>
            }
          />
          <div className='ant-page-header-heading-sub-title'>{`Case #${matterNumber}`}</div>
        </div>
      </div>
      {accessControlHelper.VerifyAccess(accessControlHelper.ADD_QUESTIONS_IN_DOCUMENTS) &&
        questions.length === 0 ? (
        <Row>
          <Col span={12}>
            <Link to={`/add/${document}/${id}`}>
              <Button>Add Documents</Button>
            </Link>
          </Col>
        </Row>
      ) : (
        <>
          {user.user.role === accessControlHelper.CLIENT_ROLE ? (
            <Row>
              <Space direction="vertical" size="large" style={{ width: "100%" }}>
                <AnsweredQuestions
                  document={currentDocumentQ}
                  questions={questions}
                  currentCase={currentCase}
                />
              </Space>
            </Row>
          ) : (
            <>
              {showAnswered ? (
                <Row>
                  <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <AnsweredQuestions
                      document={currentDocumentQ}
                      questions={answeredQuestions}
                      currentCase={currentCase}
                    />
                  </Space>
                </Row>
              ) : (
                <Row>
                  <Space direction="vertical" size="large" style={{ width: "100%" }}>
                    <TheList
                      questions={unansweredQuestions}
                      documentQ={currentDocumentQ}
                    />
                  </Space>
                </Row>
              )}
            </>
          )}
        </>
      )}
      {accessControlHelper.VerifyAccess(accessControlHelper.UPDATE_QUESTIONS_IN_DOCUMENTS) &&
        <Tooltip title='Click to update all questions'>
          <Button type='primary' className={classes.updateButton} onClick={addResponsesToDatabase}>Update</Button>
        </Tooltip>
      }
    </>
  );
};

export default QuestionList;

import styled from "styled-components";

export const Wrapper = styled.div`
  color: black;
  font-size: 1rem;
  margin: 1rem;
  padding: 2rem;

  &:p {
    color: black;
  }
`;

import { useSelector } from "react-redux";

// ROLES
export const SUPER_ADMIN_ROLE = "super-admin";
export const CASE_ADMIN_ROLE = "admin-case"; // AKA (LAW-FIRM)
export const ATTORNEY_ROLE = "attorney";
export const CLIENT_ROLE = "client";
// ROLES END


// PERMISSIONS

export const DASHBOARD = "dashboard";
export const TECH_SUPPORT = "tech-support";
export const ACCESS_CONTROL = "access-control";

export const ACCESS_OBJECTIONS = "access-objections";
export const VIEW_ALL_OBJECTIONS = "view-all-objections";
export const VIEW_OWN_OBJECTIONS = "view-own-objections";
export const VIEW_OWN_AND_SUBORDINATES_OBJECTIONS = "view-own-and-subordinates-objections";
export const ADD_OBJECTIONS = "add-objections";
export const EDIT_OBJECTIONS = "edit-objections";
export const DELETE_OBJECTIONS = "delete-objections";




export const ACCESS_RESPONSES = "access-responses";
export const VIEW_ALL_RESPONSES = "view-all-responses";
export const VIEW_OWN_RESPONSES = "view-own-responses";
export const VIEW_OWN_AND_SUBORDINATES_RESPONSES = "view-own-and-subordinates-responses";
export const ADD_RESPONSES = "add-responses";
export const EDIT_RESPONSES = "edit-responses";
export const DELETE_RESPONSES = "delete-responses";

export const VIEW_USERS = "view-users";
export const ADD_USERS = "add-users";
export const EDIT_USERS = "edit-users";
export const DELETE_USERS = "delete-users";
export const MANAGE_USERS = "manage-users";

export const VIEW_CASES = "view-cases";
export const ADD_CASES = "add-cases";
export const EDIT_CASES = "edit-cases";
export const DELETE_CASES = "delete-cases";
export const SHOW_CASES_ON_DASHBOARD = "show-cases-on-dashboard";
export const VIEW_CASE_DOCUMENTS = "view-case-documents";
export const MARK_RESPONSES_AS_REVIEWABLE = "mark-responses-as-reviewable";
export const ADD_QUESTIONS_IN_DOCUMENTS = "add-question-in-documents";
export const UPDATE_QUESTIONS_IN_DOCUMENTS = "update-question-in-documents";
export const EDIT_QUESTIONS_IN_DOCUMENTS = "edit-question-in-documents";


// export const DASHBOARD = "dashboard";
// export const DASHBOARD = "dashboard";
// export const DASHBOARD = "dashboard";
// export const DASHBOARD = "dashboard";
// export const DASHBOARD = "dashboard";


// PERMISSIONS END


// HELPER FUNCTIONS
export const VerifyAccess = (permission) => {
    // USAGE 
    // import * as accessControlHelper from '../../utils/accessControlHelper';
    // accessControlHelper.VerifyAccess(accessControlHelper.SHOW_CASES_ON_DASHBOARD)

    const auth = useSelector((state) => state.auth);
    // console.log(auth);
    return auth?.user?.access?.includes(permission);
}
// HELPER FUNCTIONS END

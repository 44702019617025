import * as api from "../api";

export const fetchRoles = (role_id) => async (dispatch) => {
    try {
        const { data } = await api.fetchRoles(role_id);
        dispatch({ type: "FETCH_ALL_ROLES", payload: data });
    } catch (error) {
        console.log(error);
    }
};

export const createRole = (newRole) => async (dispatch) => {
    try {
        const { data } = await api.createRole(newRole);
        dispatch({ type: "CREATE_ROLE", payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const updateRole = (_id, updatedRole) => async (dispatch) => {
    try {
        const { data } = await api.updateRole(_id, updatedRole);
        dispatch({ type: "UPDATE_ROLE", payload: data });
    } catch (error) {
        console.log(error);
    }
}
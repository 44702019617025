import { useState } from "react";
import ReactHtmlParser from 'html-react-parser';
import ObjectionsResponsesSelector from "../../QuestionList/ObjectionsResponsesSelector";
import classes from './EditibleItem.module.css'
import { Tooltip } from "antd";
import { useSelector } from "react-redux";

const EditibleItem = ({ document, question, response, id, item }) => {
  const [editMode, setEditMode] = useState(false);

  const handleClick = () => {
    if (!editMode) {
      setEditMode(!editMode)
    }
  }
  const { user } = useSelector((state) => state.auth);
  const preferred_document_font = user?.document_font ?? "arial";

  return (
    <Tooltip title={!editMode ? 'Click to edit' : ''} placement="topLeft">
      <div className={!editMode && classes.editibleItem} onClick={handleClick}>
        {editMode
          ?
          <div style={{ padding: '1rem 0' }}>
            <ObjectionsResponsesSelector
              item={item}
              id={id}
              document={document}
              question={question}
              editMode={editMode}
              setEditMode={setEditMode}
              presetTab={'Custom'}
              response={response}
            />
          </div>
          :
          <>
            <strong>
              <u>
                <p style={{ textTransform: "uppercase", fontFamily: preferred_document_font }}>
                  {document === "roggs" ? "answer: " : "response:"}
                </p>
              </u>
            </strong>
            <strong>
              {response && <p className={classes.responseChild}  style={{ fontFamily: preferred_document_font }}>{ReactHtmlParser(response)}</p>}
            </strong>
          </>
        }
      </div>
    </Tooltip>
  )
}

export default EditibleItem
import { useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { EditTextarea } from "react-edit-text";
import "react-edit-text/dist/index.css";
import classes from "./Question.module.css"
import { useGlobalContext } from "../../../../App";

const Question = ({ id, info,index }) => { 
  // console.log(info);
  const { removeItem, list, setList } = useGlobalContext();
  const [text, setText] = useState(`${info}`);

  
  const editItem = (id) => {
    console.log("text = " , text);
    const listWithEdits = list.map((item) => {
      if (item.id === id) {
        return { ...item, info: text };
      }
      return item;
    });

    setList(listWithEdits);
  };

  const handleChange = (event) => {
    const newText = event.target.value;
    setText(newText);
  };

  return (
    <div className={classes.questionContainer}>
      {/* <span className={classes.numberingColumn}>{index}:</span> */}
      <EditTextarea
        name="text"
        defaultValue={info}
        className={classes.questionText}
        value={text.toString()}
        onChange={handleChange}
        onSave={() => editItem(id)}

      />
      <DeleteOutlined
        className={classes.deleteIcon}
        style={{ fontSize: "1rem" }}
        onClick={() => removeItem(id)}
      />
    </div>
  );
};

export default Question;

import styled from "styled-components";

export const PrimaryButton = styled.button`
  background: var(--clr-primary-2);
  border-color: transparent;
  flex: 0 0 5rem;
  display: grid;
  align-items: center;
  padding: 0.25rem;
  text-transform: capitalize;
  letter-spacing: 2px;
  border-top-right-radius: var(--radius);
  border-bottom-right-radius: var(--radius);
  cursor: pointer;
  content: var(--clr-primary-1);
  transition: var(--transition);
  font-size: 0.85rem;
  margin-left: 1rem;
`;
export const TableWrapper = styled.table`
  th,
  td {
    text-align: left;
  }
  table,
  th,
  td {
    border: 1px solid black;
  }
`;
import classes from './LandingCard.module.css'
import { UserOutlined } from '@ant-design/icons'

export const LandingCard = ({
  img,
  title,
  count
}) => {

  return (
    <div className={classes.landingCard}>
      <div className={classes.titleIcon}>
        <img src={img} alt="" />
      </div>
      <div className={classes.title}>{title}</div>
      <div>
        <UserOutlined className={classes.userCountIcon} />
        <span className={classes.count}>{count}</span>
      </div>
    </div>
  )

}

export default LandingCard
import RolesList from "../../components/RolesList/RolesList";

const AccessControl = () => {
    return (
        <>
            <RolesList />
        </>
    );
};

export default AccessControl;
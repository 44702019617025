import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Popconfirm, message, Button, Switch, Card, Tooltip } from "antd";
// import Highlighter from "react-highlight-words";
// import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { fetchAllUsers, deleteUsers, enableUser } from "../../actions/users";
import * as accessControlHelper from '../../utils/accessControlHelper';

const Users = () => {
  //todo

  // create a tree view for users.
  // fetch all users, and change the maping mechanism. 

  const dispatch = useDispatch(fetchAllUsers());
  const user = useSelector((state) => state.auth);


  const can_edit = accessControlHelper.VerifyAccess(accessControlHelper.EDIT_USERS);
  const can_delete = accessControlHelper.VerifyAccess(accessControlHelper.DELETE_USERS);


  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filteredInfo, setFilteredInfo] = useState(null);
  const [sortedInfo, setSortedInfo] = useState(null);
  useEffect(() => {
    dispatch(fetchAllUsers(user));
  }, [dispatch]);

  //const [users, setUsers] = useState([]);
  const users = useSelector((state) => state.users);

  const removeUser = (id) => {
    dispatch(deleteUsers(id));
    message.success("User has been deleted");
  };


  const enableUserFunc = (checked, event, record) => {
    if (event.name === undefined) {
      event.persist();
    }
    if (record !== undefined) {
      record.is_enabled = checked;
      console.log(record);
      dispatch(enableUser(record._id, record));
      if (checked) {
        message.success("User has been Enabled");
      } else {
        message.success("User has been Disabled");
      }
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilteredInfo(null);
  };

  const clearAll = () => {
    setFilteredInfo(null);
    setSortedInfo(null);
  };

  const setAgeSort = () => {
    setSortedInfo({
      order: "descend",
      columnKey: "age",
    });
  };

  console.log("USERS...", users);
  // let output = users !== null ? (users ?? []).reduce(function (accumulator = [], cur) {
  //   if (cur.user_id !== undefined) {
  //     let name, found;
  //     if (user.user.role === accessControlHelper.CASE_ADMIN_ROLE || user.user.role === accessControlHelper.ATTORNEY_ROLE) {
  //       name = cur?.user_id;
  //     } else {
  //       name = cur?.user_id?.name ?? 'name not found';
  //     }
  //     found = accumulator.find(function (elem) {
  //       return elem?.name === name;
  //     });

  //     if (found) {
  //       if (user.user.role === accessControlHelper.CASE_ADMIN_ROLE || user.user.role === accessControlHelper.ATTORNEY_ROLE) {
  //         found.name = cur?.user_id?.name ?? 'name not found';
  //       } else {
  //         found.name = cur?.user_id;
  //       }
  //     } else {
  //       accumulator.push(cur?.user_id);
  //     }
  //     return accumulator;
  //   }
  // }, [])
  //   : [];

  // var filter = [];
  // output.forEach((element) => {
  //   if (element?._id === user.user.id) {
  //     filter.push({ text: "Me", value: element._id });
  //   } else {
  //     filter.push({ text: element?.name, value: element?._id });
  //   }
  // });

  const columns = [
    {
      title: "Status",
      dataIndex: "is_enabled",
      key: "is_enabled",
      render: (is_enabled, record) => {
        if ((user.user.role === accessControlHelper.ATTORNEY_ROLE && user.user.id == record.user_id) || user.user.role === accessControlHelper.SUPER_ADMIN_ROLE || user.user.role === accessControlHelper.CASE_ADMIN_ROLE) {
          return (<Switch
            checkedChildren="Enabled"
            unCheckedChildren="Disabled"
            checked={record.is_enabled}
            onChange={(checked, event) => enableUserFunc(checked, event, record)}
          />);
        }
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        // <Link to={`/users/${record._id}`}>
        <>{text}</>
      ),
      // </Link>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => (
        // <Link to={`/users/${record._id}`}>
        <>{text}</>
      ),
      // </Link>,
    },
    // {
    //   title: "Owner",
    //   dataIndex: "user_id",
    //   key: "user_id",
    //   filters: filter,
    //   filteredValue: filteredInfo ? filteredInfo.name : null,
    //   onFilter: (value, record) => {
    //     if (record.user_id !== undefined) {
    //       if (record?._id === user?.user.id) {
    //         return false;
    //       }
    //       return record?.user_id?._id?.includes(value);
    //     } else {
    //       return false;
    //     }
    //   },
    //   ellipsis: true,
    //   render: (text, record) => {
    //     if (
    //       record !== undefined &&
    //       (record.type === "admin-att" || record.type === "client")
    //     ) {
    //       return (
    //         // <Link to={`/users/${record._id}`}>
    //         <>
    //           {record.user_id !== undefined && record.user_id !== null
    //             ? record.user_id.type === "admin-sup"
    //               ? `Super Admin`
    //               : `Law Firm`
    //             : ""}
    //         </>
    //         // </Link>
    //       );
    //     } else if (record !== undefined && record.type === "admin-cas") {
    //       return (

    //         <>
    //           {record.user_id !== undefined && record.user_id !== null
    //             ? `${(record.user_id.name !== undefined) ? '' : ''} Super Admin`
    //             : ""}
    //         </>
    //       );
    //     } else if (record !== undefined && record.type === "admin-sup") {
    //       return;
    //       // <Link to={`/users/${record._id}`}>{"-"}
    //       // </Link>;
    //     }
    //   },
    // },
    {
      title: "Role",
      dataIndex: "role_id",
      key: "role_id",
      render: (text, record) => (
        // <Link to={`/users/${record._id}`}>
        <>{text.name}</>

        // </Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text) => <p>{moment(text).fromNow()}</p>,
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "_id",
      render: (_id, record) => {
        return (
          <Space size="middle">
            {(can_edit) &&
              <Link to={`/users/edit/${_id}`}>
                <EditOutlined />
              </Link>
            }
            {(can_delete) &&
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => removeUser(_id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            }
          </Space>
        );
      },
    },
  ].filter(item => !item.hidden);

  const tableData = Array.isArray(users)? users.map((users) => {
    const { name, email, role_id, _id, date, is_enabled } = users;
    return {
      key: _id,
      name,
      email,
      role_id,
      date,
      is_enabled,
      _id,
    };
  }) : [];

  sortedInfo === null && setSortedInfo({});
  filteredInfo === null && setFilteredInfo({});

  return (

    <Card hoverable>
      <div style={{ marginBottom: 20, display: "flex", justifyContent: "flex-end" }}>
        {accessControlHelper.VerifyAccess(accessControlHelper.ADD_USERS) && (
          <Link to={"/users/new"}>
            <Button type="primary">Add New User</Button>
          </Link>
        )}
      </div>
      <Table columns={columns} dataSource={tableData} />
    </Card>


    // <Card hoverable>
    //   {accessControlHelper.VerifyAccess(accessControlHelper.ADD_USERS) && !users.length ? (
    //     <div>
    //       <div
    //         style={{
    //           marginBottom: 20,
    //           display: "flex",
    //           justifyContent: "flex-end",
    //         }}
    //       >
    //         <Link to={"/users/new"}>
    //           <Button type="primary">Add New User</Button>
    //         </Link>
    //       </div>
    //       <div>
    //         <Table columns={columns} />
    //       </div>
    //     </div>
    //   ) : (
    //     <div>
    //       <div
    //         style={{
    //           marginBottom: 20,
    //           display: "flex",
    //           justifyContent: "flex-end",
    //         }}
    //       >
    //         {accessControlHelper.VerifyAccess(accessControlHelper.ADD_USERS) ? (
    //           users.length < user.user.att_limit ? (
    //             <Link to="/users/new">
    //               <Button type="primary">Add New User</Button>
    //             </Link>
    //           ) : (
    //             <Tooltip title="Sorry, Your Attorney’s limit exceeded. Please contact your administrator" color="red">
    //               <Button type="primary" disabled> Add New User</Button>
    //             </Tooltip>
    //           )
    //         ) : null}

    //       </div>
    //       <div>
    //         {/* <Table columns={columns} dataSource={tableData} /> */}
    //       </div>
    //     </div>
    //   )}
    // </Card>
  );
};

export default Users;

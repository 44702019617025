import classes from "./DiscoveryResponseField.module.css"

const DiscoveryResponseField = ({ document, setDocument }) => {

  return (
    <section>
      <form action="" className={classes.FormContainer}>
        <div >
          <div>
            <textarea
              type="text"
              name="document"
              style={{
                padding: 15,
                border: "1px solid grey",
                borderRadius: 5,
                width: '100%',
                maxWidth: '100%'
              }}
              rows={10}

              placeholder="Insert questions here"
              value={document}
              onChange={(e) => setDocument(e.target.value)}
            />
          </div>
        </div>
      </form>    

    </section>
  );
};

export default DiscoveryResponseField;

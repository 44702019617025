export default (users = [], action) => {
  switch (action.type) {
    case "FETCH_ALL_USER":
      return action.payload;
    case "CREATE":
      return [...users, action.payload];
    case "UPDATE":
      return action.payload;
    case "ADD_USER_PHONE":
      return action.payload;
    case "DELETE_USER":
      return users?.filter((users) => users._id !== action.payload);
    case "RESET_PASSWORD":
      return action.payload;
    case "RESET_PASSWORD_TOKEN":
      return action.payload;
    case "UPDATE_USER_AUTH":
      return {
        isEnabled: action.payload,
      };
    default:
      return users;
  }
};



import Question from "./Question/Question";

const List = ({ questions }) => {
  return (
    <>
      {questions.map((item, index) => {
        const { id } = item;
        item.index = index+1;
        return <Question key={id} {...item} />;
      })}
    </>
  );
};

export default List;

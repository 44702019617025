

import AddNewCases from "../../components/AddNewCase/AddNewCase";

const AddNew = () => {
  return (
    <>
      <AddNewCases />
    </>
  );
};

export default AddNew;

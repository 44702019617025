import axios from 'axios';
import  { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Form, Input, Typography, Card, Button } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import './Response.css'
import { useSelector } from 'react-redux';

import Checkbox from 'antd/lib/checkbox/Checkbox';

const initialState = { title: '', content: '', assignTo: [] }

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'
const NewResponse = () => {
  
  const user = useSelector((state) => state.auth);
  const type = user.user.role;
  const name = user?.user?.name;
  let _Id = '';

  // if(user?.user?.type == 'admin-cas') {
  //   _Id = user?.user?.id
  // } else if(user?.user?.type == 'admin-sup') {
  //   _Id = user?.user?.type
  // } else {
  //   _Id = user?.user?.id
  // }
  _Id = user?.user?.id

  const [response, setResponse] = useState({...initialState});
  
  const navigate = useNavigate();

  const createResponse= (response) => {

    axios.post(BASE_URL+'/api/responses',{ ...response, creator: type , name: name, userId: _Id })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createResponse(response);
    navigate('/responses');
  }

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setResponse({...response, content: data});
  }
  
  const onChange= (e) => {
    const { value, checked } = e.target;
    const { assignTo } = response;

    if(checked) {
      setResponse({
        ...response, assignTo: [...assignTo, value]
      })
    }
    else {
      setResponse({
        ...response, assignTo: assignTo.filter((e)=> e !== value)
      })
    }
  }

  const handleClear = () => {
    console.log('clearing')
    setResponse({ ...initialState })
  }

  return (
    <Card hoverable>
      <Form>
        <Typography.Title>
          New Response
        </Typography.Title>
        <Form.Item name="title" label="Title">
          <Input
            onChange={(e) => setResponse({ ...response, title: e.target.value})}
            value={response.title}
            id="title"
            type="text"
            style={{ width: 500 }}
          />
        </Form.Item>
        <Form.Item name="content" label="Content">
          <CKEditor
            editor={ClassicEditor}
            data={response.content}
            onChange={handleChange}
          />
        </Form.Item>
        <Form.Item name="assignTo" label="Assign To">
          <Checkbox checked={response.assignTo?.includes('RFA')} onChange={onChange} value="RFA">RFA</Checkbox>
          <Checkbox checked={response.assignTo?.includes('RFP')} onChange={onChange} value="RFP">RFP</Checkbox>
          <Checkbox checked={response.assignTo?.includes('ROGGS')} onChange={onChange} value="ROGGS" >ROGGS</Checkbox>
        </Form.Item>
        <div className='submit-container'>
          {/* <Form.Item name="add-btn" className="submit-button">
            <Button
              type="submit"
              onClick={() => handleClear()}
            >
              Clear Form
            </Button>
          </Form.Item> */}
          <Form.Item name="add-btn" className="submit-button">
            <Button
              type="submit"
              onClick={handleSubmit}
            >
              Add New Response
            </Button>
          </Form.Item>
        </div>
       </Form>
    </Card>
  )
}

export default NewResponse

import  { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer,
  Form,
  Button,
  Input,
  Avatar,
  List,
} from "antd";
import { PlusOutlined, UserOutlined } from "@ant-design/icons";
import { Comment } from '@ant-design/compatible';
import {
  fetchQuestionComments,
  storeQuestionComments,
} from "../../actions/users";
import moment from "moment";

const { TextArea } = Input;

const CommentList = ({ comments }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}
    itemLayout="horizontal"
    renderItem={(props) => <Comment {...props} />}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const DrawerForm = ({ question, document, onchange }) => {
  const [visible, setVisible] = useState(true);
  const [value, setValue] = useState("");
  // const [commentPresent, setCommentPresent] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const data = {
    question: question._id,
    document: document._id,
  };
  const dispatch = useDispatch(fetchQuestionComments(data));
  useEffect(() => {
    dispatch(fetchQuestionComments(data));
  }, [dispatch]);

  const comments = useSelector((state) => state.comments);
  const userCurrent = useSelector((state) => state.auth);

  useEffect(() => {
    if (comments) {
      comments.forEach((element, index) => {
        comments[index].author = comments[index].email;
        comments[index].avatar = "https://joeschmoe.io/api/v1/random";
        comments[index].datetime = moment(comments[index].createTime).format(
          "DD MMM YYYY hh:mm a"
        );
        comments[index].content = <p>{comments[index].content}</p>;
        delete comments[index]._id;
        delete comments[index].cid;
        delete comments[index].question;
        delete comments[index].document;
        delete comments[index].createTime;
        delete comments[index].owner;
        delete comments[index].isOwner;
        delete comments[index].email;
        delete comments[index].isDeleted;
        delete comments[index].__v;
      });
    }
  }, [comments]);
  const showDrawer = () => {
    setVisible(true);
    onchange(true);
  };

  const onClose = () => {
    setVisible(false);
    onchange(false);
  };

  const handleSubmit = () => {
    if (!value) {
      return;
    }

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      const data = {
        question: question._id,
        document: document._id,
        content: value,
        user_id: userCurrent.user.id,
        isOwner: true,
        email: userCurrent.user.email,
        isDeleted: 0,
      };
      console.log(data);
      setValue("");
      dispatch(storeQuestionComments(data));
    }, 1000);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <>
      <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
        Comments
      </Button>
      <Drawer
        title="Comments"
        width={720}
        onClose={onClose}
        visible={visible}
        bodyStyle={{ paddingBottom: 80, paddingLeft: 20 }}
      >
        {comments.length > 0 && <CommentList comments={comments} />}
        <Comment
          avatar={
            <UserOutlined style={{ fontSize: '24px', border: '1px solid grey', borderRadius: '50%', padding: '4px' }} frameBorder={true} />
          }
          content={
            <Editor
              onChange={(e) => handleChange(e)}
              onSubmit={() => handleSubmit()}
              submitting={submitting}
              value={value}
            />
          }
        />
      </Drawer>
    </>
  );
};

export default DrawerForm;

import { useEffect, useState } from "react";
import { Col, Modal, Card, Tag, Button, Tabs } from "antd";
import { PageHeader } from '@ant-design/pro-layout';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import DiscoveryResponseField from "../DiscoveryResponseField/DiscoveryResponseField";
import { useGlobalContext } from "../../App";
import { updateCase } from "../../actions/cases";
import List from "../DiscoveryResponseField/List/List";
import UploadQuestions from "./UploadQuestions/UploadQuestions";

const AddQuestions = () => {
  const { list, setList } = useGlobalContext();
  const { id, document } = useParams();
  const [caseData, setCaseData] = useState("");
  const [parseDocument, setDocument] = useState("");
  const dispatch = useDispatch();

  const currentCase = useSelector((state) =>
    id ? state.cases.find((item) => item._id === id) : null
  );

  useEffect(() => {
    if (currentCase) setCaseData(currentCase);
  }, [currentCase]);

  const { matterNumber, caseName } = caseData;

  const success = () => {
    Modal.success({
      content: `Questions added to ${document.toUpperCase()}`,
    });
  };

  const addQuestions = () => {
    const editedDocuments = currentCase.documents.map((item) => {
      if (item.documentType === document) {
        return { documentType: document, questions: list };
      }
      return item;
    });
    const caseInfo = { ...currentCase, documents: editedDocuments };

    dispatch(updateCase(id, caseInfo));
    success();
    setList([]);
  };

  const generateQuestions = (e) => {
    console.log('parseDocument', parseDocument);
    if (parseDocument) {
      const allQuestions = getQuestions(parseDocument);
      setList(allQuestions);
    }
  };

  const addDocumentAndGenerateQuestions = (text) => {
    const questions = getQuestions(text);
    console.log("addDocumentAndGenerateQuestions",questions);
    setList(questions);
  }

  const getQuestions = (text) => {
    const regex = /\d+\..+?(?=\d+\.)|(\d+\..+)/g;
    const remove_index_regex = /^\d+\.\s+/;

    const potential_questions = text.match(regex);

    const questions = potential_questions?.map((question) => {
      return {
        id: uuidv4(),
        // info: question?.replace(remove_index_regex, "").trim(),
        info: question?.trim(),
        response: "",
        isAnswered: false,
      };
    })
    // console.log(questions);

    return questions;

  };

  // const askGptForQuestions = async (rawPdfText) => {

  //   const regex = /\d+\..+?(?=\d+\.)|(\d+\..+)/g;

  //   // Extracting the questions from the text
  //   const questions = rawPdfText.match(regex);

  //   // Logging the extracted questions
  //   return questions;
  //   console.log(questions);


  //   // const openai = new OpenAI({
  //   //   apiKey: "sk-proj-EmjmG58DqvvfxjBZipphT3BlbkFJiPDyZkwLuylhnCQShL8i",
  //   //   organization: 'org-yGIJqqSFM36UptkhowXCo829',
  //   //   project: 'proj_evndhWR6f6LjpF3oHtkLcilS',
  //   //   dangerouslyAllowBrowser: true
  //   // });

  //   // const completion = await openai.chat.completions.create({
  //   //   messages: [{ "role": "system", "content": "You are a helpful assistant." },
  //   //   { "role": "user", "content": "Who won the world series in 2020?" },
  //   //   { "role": "assistant", "content": "The Los Angeles Dodgers won the World Series in 2020." },
  //   //   { "role": "user", "content": "Where was it played?" }],
  //   //   model: "gpt-3.5-turbo-instruct",
  //   // });

  //   // console.log("RESPONSE FROM GPT", completion.choices[0]);
  // }

  const tabs = [
    // {
    //   key: '1',
    //   label: `Insert Questions`,
    //   children: 
    //     <>
    //       <DiscoveryResponseField
    //         document={parseDocument}
    //         setDocument={setDocument}
    //       />
    //       <div style={{ marginTop: 12, display: 'flex'  }}>
    //         <Button onClick={generateQuestions} style={{padding: '16px', marginRight: list?.length > 0 ? '16px' : '0px'}} >Generate Questions</Button>
    //         {list?.length > 0 && <Button onClick={() => setList(() => [])} style={{padding: '16px'}} >Clear Questions</Button>}
    //       </div>
    //     </>
    //   ,
    // },
    {
      key: '2',
      label: `Upload Questions`,
      children: <UploadQuestions setDocument={addDocumentAndGenerateQuestions} generateQuestions={generateQuestions} />,
    }
  ];

  return (
    <>
      <div style={{ paddingBottom: '4px' }}><Link to={`/dashboard/${id}`}>Back To Case</Link></div>
      <Card hoverable>
        <Col>
          <PageHeader
            title={`Case #${matterNumber}`}
            subTitle={caseName}
            style={{ backgroundColor: "white", padding: 0 }}
            extra={<Tag color='green'>{document.toUpperCase()}</Tag>}
          />
          <Tabs style={{ paddingTop: '1rem' }} defaultActiveKey="1" items={tabs} />
          {list?.length > 0 && (
            <>
              <div>
                <List questions={list} />
              </div>
            </>
          )}
          {list?.length > 0 &&
            <div style={{ marginTop: 12, width: 'fit-content' }}>
              <Button onClick={addQuestions} style={{ padding: '16px' }}>Add Questions to Document</Button>
            </div>
          }
        </Col>
      </Card>
    </>
  );
};

export default AddQuestions;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Form,
    Button,
    Typography,
    Card,
    Modal,
    Select,
} from "antd";
import { useParams } from "react-router-dom";
import axios from "axios";
import { createTicket, updateTicket } from "../../actions/TechSupport";

const { Option } = Select;

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

const UpdateTicket = () => {

    const user = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    let { id } = useParams();
    const [editMode, setEditMode] = useState(false);

    const [formData, setFormData] = useState({
        subject: "",
        issue: "",
        status: "Pending",
        response: [],
    });

    const [currentResponse, setCurrentResponse] = useState({
        from: user.user.name,
        response: "",
    });

    useEffect(() => {
        if (id) {
            axios.get(`${BASE_URL}/api/tickets/${id}`).then(
                (response) => {
                    setFormData({ ...response.data.tickets })
                    setEditMode(true);
                }

            )
        }
    }, [])

    const [formError, setFormError] = useState({
        responseErr: "",
    });

    const handleSubmit = () => {
        if (currentResponse.response === "") {
            setFormError({
                ...formError,
                statusErr: "Response is required.",
            });
        }
        if (formData.responseErr != "") {

            // SUBMIT FORM HERE
            let responses = formData.response;
            responses.push(currentResponse)
            setFormData({ ...formData, response: responses })
            console.log(formData)

            dispatch(updateTicket(id, formData));
            Modal.success({
                content: "Ticket updated successfully",
            });
            window.location.replace("/tech-support");
        }

    };

    return <Card>
        <Typography.Title>
            View Ticket
        </Typography.Title>

        <h5>Subject</h5>
        <p>{formData.subject}</p>

        <h5>Issue</h5>
        <p>{formData.issue}</p>


        <h4>Responses</h4>
        {formData.response.map((response, i) => (
            <div key={i}>
                <h5>{response.from}</h5>
                <p>{response.response}</p>
            </div>
        ))}

        <Form layout="horizontal" className="discovery">


            <textarea
                name="response"
                id="response"
                rows="10"
                style={{ width: "97%" }}
                onChange={e => setCurrentResponse({ ...currentResponse, response: e.target.value })}
            >
                {currentResponse.response}
            </textarea>
            {formError.responseErr ? (
                <>
                    <div>
                        <p style={{ marginTop: 2, color: "red" }}>
                            {formError.responseErr}
                        </p>
                    </div>
                </>
            ) : null}


            <Form.Item name="submit-button">
                <div style={{ marginTop: 10, textAlign: "right", width: "100%" }}>
                    <Button htmlType="submit" type="primary" onClick={() => { handleSubmit() }}>
                        Add Response
                    </Button>
                </div>
            </Form.Item>
        </Form >
    </Card >
}

export default UpdateTicket;
export default (roles = [], action) => {
    switch (action.type) {
        case "FETCH_ALL_ROLES":
            return action.payload;
        case "CREATE_ROLE":
            return [...roles, action.payload];
        case "UPDATE_ROLE":
            return roles.map((role) =>
                role._id === action.payload._id ? action.payload : role
            );
        default:
            return roles;
    }
};

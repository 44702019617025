import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { Form, Input, Button, Typography, Card, Alert, Row, Col, Select, } from "antd";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../actions/users";
import { SET_CURRENT_USER } from "../../actions/types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as accessControlHelper from '../../utils/accessControlHelper';

const { Option } = Select;
const Profile = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    type: "",
    errors: {},
    phone: "",
    signature: "",
    document_font: "",
  });

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const userCurrent = useSelector((state) => state.auth);
  const currentUser = userCurrent.user;
  const errors = useSelector((state) => state.errors);

  useEffect(() => {
    console.log(currentUser)
    if (currentUser) {
      currentUser.password = "";
      setUser(currentUser);
      form.setFieldsValue(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (errors) {
      setUser((prevState) => ({ ...prevState, errors: errors }));
    }
  }, [errors]);

  const onChange = (e) => {
    if (typeof e === "object") {
      if (e.hasOwnProperty('id')) {
        setUser((prevState) => ({ ...prevState, [e.id]: e.data }));
        return;
      }
      e.persist();
      setUser((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
    } else {
      setUser((prevState) => ({ ...prevState, type: e }));
    }
  };
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setUser({ ...user, signature: data });
  }

  const onFontChange = (value) => {
    setUser({ ...user, document_font: value });
  }

  const setCurrentUser = (decoded) => {
    return {
      type: SET_CURRENT_USER,
      payload: decoded,
    };
  };

  const onSubmit = () => {
    if (currentUser) {
      if (user.password === "") {
        let newUser = {
          name: user.name,
          phone: user.phone,
          email: user.email,
          type: user.type,
          role_id: user.role_id,
          document_font: user.role_id,
        };
        if (currentUser.role == accessControlHelper.ATTORNEY_ROLE || currentUser.role == accessControlHelper.CASE_ADMIN_ROLE) newUser.signature = user.signature
        dispatch(updateUser(currentUser.id, newUser));
      } else {
        let newUser = {
          name: user.name,
          phone: user.phone,
          email: user.email,
          signature: user.signature,
          password: user.password,
          password2: user.password2,
          // type: user.type,
          role_id: user.role_id,
          document_font: user.document_font,
        };
        if (currentUser.role == accessControlHelper.ATTORNEY_ROLE || currentUser.role == accessControlHelper.CASE_ADMIN_ROLE) newUser.signature = user.signature
        dispatch(updateUser(currentUser.id, newUser));
      }
      const newUser = user;
      delete newUser.password;
      delete newUser.password2;
      delete newUser.errors;
      // Set current user
      localStorage.setItem("user", JSON.stringify(newUser) || []);
      dispatch(setCurrentUser(newUser));
    }
  };

  return (
    <>
      <Card hoverable>
        <Form
          layout="vertical"
          onFinish={onSubmit}
          onValuesChange={() => { }}
          form={form}
        >
          <Row>
            <Col span={24}>
              <Typography.Title>Profile</Typography.Title>
            </Col>
          </Row>

          <Form.Item name="name" label="Name">
            {errors.name ? (
              <Alert message={user.errors.name} type="error" />
            ) : (
              ""
            )}
            <Input
              onChange={onChange}
              value={user.name}
              error={errors.name}
              id="name"
              type="text"
              style={{ width: 500 }}
              className={classnames("", {
                invalid: errors.name,
              })}
            />
          </Form.Item>
          <Form.Item name="email" label="Email">
            {errors.email ? (
              <Alert message={user.errors.email} type="error" />
            ) : (
              ""
            )}
            <Input
              onChange={onChange}
              value={user.email}
              error={errors.email}
              id="email"
              type="email"
              disabled={true}
              style={{ width: 500 }}
              className={classnames("", {
                invalid: errors.email,
              })}
            />
          </Form.Item>

          <Form.Item name="phone" label="Phone Number">
            {errors.phone ? (
              <Alert message={user.errors.phone} type="error" />
            ) : (
              ""
            )}
            {user.phone ? (
              <Input
                defaultValue={user.phone}
                onChange={onChange}
                error={errors.phone}
                value={user.phone}
                id="phone"
                type="text"
                style={{ width: 500 }}
                className={classnames("", {
                  invalid: errors.phone,
                })}
              />
            ) : (
              <Input
                onChange={onChange}
                error={errors.phone}
                value={user.phone}
                id="phone"
                type="text"
                style={{ width: 500 }}
                className={classnames("", {
                  invalid: errors.phone,
                })}
              />
            )}
          </Form.Item>
          <Form.Item name="signature" label="Add Signature here">
            <CKEditor
              editor={ClassicEditor}
              data={user.signature}
              onChange={handleChange}
            />
          </Form.Item>


          <Form.Item name="document_font" label="Select Preferred Document Font">

            <Select value={user.document_font} onChange={onFontChange}>
              <Option value="Arial">Arial</Option>
              <Option value="Times New Roman">Times New Roman</Option>
              <Option value="Calibri">Calibri</Option>

            </Select>

          </Form.Item>


          <Form.Item name="password" label="Password">
            {errors.password ? (
              <Alert message={user.errors.password} type="error" />
            ) : (
              ""
            )}
            <Input
              onChange={onChange}
              value={user.password}
              error={errors.password}
              placeholder="Enter new password"
              id="password"
              type="password"
              style={{ width: 500 }}
              className={classnames("", {
                invalid: errors.password,
              })}
            />
          </Form.Item>

          <Form.Item name="password2" label="Confirm Password">
            {errors.password2 ? (
              <Alert message={user.errors.password2} type="error" />
            ) : (
              ""
            )}
            <Input
              onChange={onChange}
              value={user.password2}
              error={errors.password2}
              id="password2"
              type="password"
              placeholder="Confirm new password"
              style={{ width: 500 }}
              className={classnames("", {
                invalid: errors.password2,
              })}
            />
          </Form.Item>

          {currentUser.role == accessControlHelper.ATTORNEY_ROLE || currentUser.role == accessControlHelper.CASE_ADMIN_ROLE ? <Form.Item name="signature" label="">
            {/* <CKEditor
              editor={ Editor }
              data={user.signature}
              onReady={ editor => {
                editor.setData( user.signature );
              } }
              onChange={ ( event, editor ) => {
                  const data = editor.getData();
                  onChange({id: "signature", data});
              } }
              onBlur={ ( event, editor ) => {
              } }
              onFocus={ ( event, editor ) => {
              } }
            /> */}
          </Form.Item> : <></>}

          <Form.Item>
            <Row>
              <Col span={12}>
                <Button
                  htmlType="submit"
                  type="dashed"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  {"Save Changes"}
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default Profile;

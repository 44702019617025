import * as api from "../api";

export const fetchTickets = (ticket_id) => async (dispatch) => {
    try {
        const { data } = await api.fetchTickets(ticket_id);
        dispatch({ type: "FETCH_ALL_TICKETS", payload: data });
    } catch (error) {
        console.log(error);
    }
};

export const createTicket = (newTicket) => async (dispatch) => {
    try {
        const { data } = await api.createTicket(newTicket);
        dispatch({ type: "CREATE_TICKET", payload: data });
    } catch (error) {
        console.log(error);
    }
}

export const updateTicket = (_id, updatedTicket) => async (dispatch) => {
    try {
        const { data } = await api.updateTicket(_id, updatedTicket);
        dispatch({ type: "UPDATE_TICKET", payload: data });
    } catch (error) {
        console.log(error);
    }
}
import { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import { Form, Input, Button, Alert, notification } from 'antd';
import "../../styles.css";
import TermsAndConditions from "../UserAgreement/TermsAndConditions";

const Login = (props) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
    errors: {}
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const errors = useSelector((props) => props.errors);
  useEffect(() => {
    if (errors) {
      console.log(errors);
      if (errors.userNotEnabled !== undefined) {
        notification.open({
          message: 'Suspended!',
          description:
            'Your account has been suspended. Please Contact your administrator.',
        });
      }
      if (errors.emailnotfound !== undefined) {
        notification.open({
          message: 'Error!',
          description:
            'Email you entered is not found in our records.',
        });
      }
      setUser((prevState) => ({ ...prevState, errors: errors }));
    }
  }, [errors]);


  if (props.auth.isAuthenticated && props.auth.user.first_login) {

    //console.log("in login.js file the navigation rout is redirected ");

    navigate("/dashboard");

    //return <TermsAndConditions/>;
    // console.log(props.auth);
    // if (!props.auth.FirstLogin){
    //   navigate("/dashboard");
    // }
    // else{

    // }

  }
  else if (props.auth.isAuthenticated) {
    return <TermsAndConditions />;
  }


  const onChange = e => {
    e.persist();
    setUser((prevState) => ({ ...prevState, errors: {} }));
    setUser((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));

  };
  const onSubmit = e => {
    if (user?.email && user?.password) {
      const userData = {
        email: user.email,
        password: user.password
      };

      dispatch(loginUser(userData))
    }
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 4 }}
      onFinish={onSubmit}
    >

      <div style={{ marginLeft: '25px' , marginBottom: '25px' }}>
        <img src="./img/logonew_dark.png" alt="" />
      </div>
      <Form.Item
        name="email"
      >
        <div className="custom-form-label">Email</div>
        {errors.email ? <Alert message={user.errors.email} type="error" /> : ''}
        <Input
          onChange={onChange}
          value={user.email}
          error={user.errors.email}
          id="email"
          type="email"
          placeholder="John.snow@gmail.com"
          className={classnames("", {
            invalid: user.errors.email || user.errors.emailnotfound
          })} />
      </Form.Item>

      <Form.Item
        name="password"
      >
        <div className="custom-form-label">Password</div>
        {errors.passwordincorrect ? <Alert message={user.errors.passwordincorrect} type="error" /> : ''}
        <Input.Password
          onChange={onChange}
          value={user.password}
          error={user.errors.password}
          id="password"
          type="password"
          placeholder="*********"
          className={classnames("", {
            invalid: user.errors.password || user.errors.passwordincorrect
          })}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ /** offset: 12, span: 16 **/ }}>
        <Link to='/reset-password'
          style={{ color: '#2c5282' }}
        >Forgot password?</Link>
      </Form.Item>

      <Form.Item wrapperCol={{ /** offset: 10, span: 16 **/ }}>
        <Button type="primary" htmlType="submit" className="btn btn-success">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

/* class Login extends Component {
          constructor(props) {
                    super(props);
                    this.state = {
                              email: "",
                              password: "",
                              errors: {}
                    };
                    
          } 

          componentDidMount() {
                    
                    console.log(this.props);
                    if (this.props.errors) {
                              this.setState({
                              errors: this.props.errors
                              });
                    }
          }

          static getDerivedStateFromProps(nextProps , state) {
                    if (nextProps.auth.isAuthenticated) {
                              
                              nextProps.navigation("/dashboard"); // push user to dashboard when they login
                              if (nextProps !== state) {
                                        return {
                                          value: nextProps
                                        };
                              }
                    }
                    return null;
          }
          onChange = e => {
                    this.setState({ [e.target.id]: e.target.value });
          };
          onSubmit = e => {
                    //e.preventDefault();
                    const userData = {
                              email: this.state.email,
                              password: this.state.password
                    };
                    this.props.loginUser(userData);
          };
render() {
    const { errors } = this.state;
return (
      <Form
      name="basic"
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 4 }}
      onFinish={this.onSubmit}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input 
        onChange={this.onChange}
        value={this.state.email}
        error={errors.email}
        id="email"
        type="email"
        className={classnames("", {
          invalid: errors.email || errors.emailnotfound
        })} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password 
        onChange={this.onChange}
        value={this.state.password}
        error={errors.password}
        id="password"
        type="password"
        className={classnames("", {
          invalid: errors.password || errors.passwordincorrect
        })}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 12, span: 16 }}>
        <a className="login-form-forgot" href="">
        <Link to='/reset-password'>Forgot password?</Link>
        </a>
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
    );
  }
}
*/
Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginUser }
)(function (props) {

  return <Login {...props} />;
});
import { PageHeader } from '@ant-design/pro-layout';
import { useState } from "react";
import { Button, Card, Tag, Tooltip } from "antd";
import { FileWordOutlined, FilePdfOutlined } from '@ant-design/icons';

import classes from './CaseHeader.module.css'

const CaseHeader = ({
  caseName,
  document,
  matterNumber,
  rightAlignChildren,
  downloadPdf,
  downloadingPDF,
  downloadPdfWithoutQA,
  downloadingPDFWithoutQA,
  downloadingWordDoc,
}) => {

  return (
    <Card >
      <div className={""}>
        <div>
          <PageHeader
            title={caseName}
            className={classes.pageHeader}
            subTitle={
              <div>
                <span><Tag color='green' key="1">{document.toUpperCase()}</Tag></span>
              </div>
            }
            style={{ backgroundColor: "white", padding: '0rem' }}
            extra={rightAlignChildren}
          />
          <div className='ant-page-header-heading-sub-title'>{`Case #${matterNumber}`}</div>
        </div>
        <div className={classes.actionsContainer}>
          <Tooltip title='Download as PDF Without QA'>
            <Button
              type='dashed'
              loading={downloadingPDFWithoutQA}
              onClick={downloadPdfWithoutQA}>
              PDF Download Without QA<FilePdfOutlined style={{ fontSize: '1.25rem' }} />
            </Button>
          </Tooltip>

          <Tooltip title='Download as PDF'>
            <Button
              type='dashed'
              loading={downloadingPDF}
              onClick={downloadPdf}>
              PDF Download <FilePdfOutlined style={{ fontSize: '1.25rem' }} />
            </Button>
          </Tooltip>

          <Tooltip title='Download as Word Doc not available yet'>
            <Button
              type='dashed'
              loading={downloadingWordDoc}
              style={{ marginLeft: 12 }}>
              Word Download <FileWordOutlined style={{ fontSize: '1.25rem' }} />
            </Button>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
}

export default CaseHeader;

import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Card } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

const TicketsList = () => {
    const [tickets, setTickets] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/tickets`)
            .then((res) => setTickets(res.data.tickets))
            .catch((error) => console.log(error))

        console.log(tickets)
    }, [tickets.length])


    const columns = [
        {
            title: "Subject",
            dataIndex: "subject",
            key: "subject",
            render: (text, record) => <Link to={`/tech-support/new/${record._id}`}>{text}</Link>,
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => <Link to={`/tech-support/new/${record._id}`}>{text}</Link>,
        },

        {
            title: "Action",
            key: "actions",
            dataIndex: "_id",
            render: (_id) => (
                <Space size="middle">
                    <Link to={`/tech-support/new/${_id}`}>
                        <EditOutlined />
                    </Link>
                </Space>
            ),
        },
    ];

    const tableData = Array.isArray(tickets)
        ? tickets.map((ticket) => {
            const { subject, _id, status } = ticket;
            return {
                key: _id,
                "subject": subject,
                "status": status,
                _id,
            };
        })
        : "";

    return (
        <>
            <Card hoverable>
                <div>
                    <div
                        style={{
                            marginBottom: 20,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Link to={"/tech-support/new"}>
                            <Button type="primary">Open Ticket</Button>
                        </Link>
                    </div>
                    <div>
                        <Table columns={columns} dataSource={tableData} />
                    </div>
                </div>
            </Card>
        </>
    );
};

export default TicketsList;

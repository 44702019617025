
import { Row, Col, Typography, Space, Tooltip, Button, Form, Input } from "antd";
import classes from './Header.module.css';
import { commaSeparatedJoin } from "../../../utils/string";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCaseHeadings } from "../../../actions/cases";

const { Text, Paragraph } = Typography;

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const Header = ({ user, caseData, document }) => {

  const dispatch = useDispatch();
  const { court, plaintiff, defendant, caseNumber, ocEmail1, ocEmail2, ocEmail3, ocEmail4, ocEmail5 } = caseData;

  const emailList = commaSeparatedJoin([ocEmail1, ocEmail2, ocEmail3, ocEmail4, ocEmail5].filter(val => val?.length > 0));

  const preferred_document_font = user?.document_font ?? "arial";

  const formatter = new Intl.DateTimeFormat('en-US', { month: 'long' });

  const initialNoticeHeading =
    document === "rfa"
      ? "NOTICE OF SERVICE OF PLAINTIFF'S RESPONSE TO DEFENDANT'S REQUEST FOR ADMISSIONS"
      : document === "rfp"
        ? "NOTICE OF SERVICE OF PLAINTIFF'S RESPONSE TO DEFENDANT'S REQUEST FOR PRODUCTION"
        : "NOTICE OF SERVICE OF PLAINTIFF'S RESPONSE TO DEFENDANT'S SET OF INTERROGATORIES";

  const initialNoticeSubheading =
    document === "rfa"
      ? "Pursuant to the Florida Rules of Civil Procedure, Plaintiff notifies this Honorable Court of Plaintiff's responses to Defendant's first request for admissions"
      : document === "rfp"
        ? "Pursuant to the Florida Rules of Civil Procedure, Plaintiff notifies this Honorable Court of Plaintiff's responses to Defendant's first request for production"
        : "Pursuant to the Florida Rules of Civil Procedure, Plaintiff notifies this Honorable Court of Plaintiff's responses to Defendant's first set of interrogatories";

  const [noticeHeading, setNoticeHeading] = useState(initialNoticeHeading)
  const [editNoticeHeading, setEditNoticeHeading] = useState(false)
  const [updatedNoticeHeading, setUpdatedNoticeHeading] = useState()

  const [noticeSubheading, setNoticeSubheading] = useState(initialNoticeSubheading)
  const [editNoticeSubheading, setEditNoticeSubheading] = useState(false)
  const [updatedNoticeSubheading, setUpdatedNoticeSubheading] = useState()


  const [certificateHeading, setCertificateHeading] = useState("CERTIFICATE OF SERVICE")
  const [editCertificateHeading, setEditCertificateHeading] = useState(false)
  const [updatedCertificateHeading, setUpdatedCertificateHeading] = useState()

  const initialCertificateSubHeading = "I HEREBY CERTIFY that a copy of the foregoing has been furnished by electronic service to {{defendant}} via email to {{email}} on this {{due_date}}";
  const [certificateSubHeading, setCertificateSubHeading] = useState(initialCertificateSubHeading)
  const [editCertificateSubHeading, setEditCertificateSubHeading] = useState(false)
  const [updatedCertificateSubHeading, setUpdatedCertificateSubHeading] = useState()

  useEffect(() => {
    if (caseData) {
      const currentDocument = caseData.documents.filter(
        (item) => item.documentType === document
      )[0];
      if (currentDocument.noticeHeading) {
        setNoticeHeading(currentDocument.noticeHeading);
      }
      setNoticeSubheading(currentDocument.noticeSubheading ?? initialNoticeSubheading);

      setCertificateHeading(currentDocument.certificateHeading ?? certificateHeading);
      setCertificateSubHeading(currentDocument.certificateSubHeading ?? certificateSubHeading);
    }
  }, [caseData]);


  const toggleEditNoticeHeading = (() => {
    let state = !editNoticeHeading
    setEditNoticeHeading(state);
  })

  const handleUpdateNoticeHeading = async () => {
    if (updatedNoticeHeading != "" && caseData) {
      const currentDocument = caseData.documents.filter(
        (item) => item.documentType === document
      )[0];

      await dispatch(updateCaseHeadings(caseData._id, currentDocument._id, {
        type: "noticeHeading",
        data: updatedNoticeHeading
      }));
      toggleEditNoticeHeading()
      setNoticeHeading(updatedNoticeHeading)
    }
  }

  const toggleEditNoticeSubheading = (() => {
    let state = !editNoticeSubheading
    setEditNoticeSubheading(state);
  })

  const handleUpdateNoticeSubheading = async () => {
    if (updatedNoticeSubheading != "" && caseData) {
      const currentDocument = caseData.documents.filter(
        (item) => item.documentType === document
      )[0];

      await dispatch(updateCaseHeadings(caseData._id, currentDocument._id, {
        type: "noticeSubheading",
        data: updatedNoticeSubheading
      }));
      toggleEditNoticeSubheading()
      setNoticeSubheading(updatedNoticeSubheading)
    }
  }


  const toggleEditCertificateHeading = (() => {
    let state = !editCertificateHeading
    setEditCertificateHeading(state);
  })

  const handleUpdateCertificateHeading = async () => {
    if (updatedCertificateHeading != "" && caseData) {
      const currentDocument = caseData.documents.filter(
        (item) => item.documentType === document
      )[0];

      await dispatch(updateCaseHeadings(caseData._id, currentDocument._id, {
        type: "certificateHeading",
        data: updatedCertificateHeading
      }));
      toggleEditCertificateHeading()
      setCertificateHeading(updatedCertificateHeading)
    }
  }


  const toggleEditCertificateSubHeading = (() => {
    let state = !editCertificateSubHeading
    setEditCertificateSubHeading(state);
  })

  const handleUpdateCertificateSubHeading = async () => {
    if (updatedCertificateSubHeading != "" && caseData) {
      const currentDocument = caseData.documents.filter(
        (item) => item.documentType === document
      )[0];

      await dispatch(updateCaseHeadings(caseData._id, currentDocument._id, {
        type: "certificateSubHeading",
        data: updatedCertificateSubHeading
      }));
      toggleEditCertificateSubHeading()
      setCertificateSubHeading(updatedCertificateSubHeading)
    }
  }



  return (
    <>
      <Row className={classes.docRow} justify='center'>
        <Text style={{ fontFamily: preferred_document_font }}>{court?.toUpperCase()}</Text>
      </Row>
      <Row className={classes.docRow}>
        <Col>
          <Text style={{ fontFamily: preferred_document_font }}>{plaintiff?.toUpperCase()},</Text>
        </Col>
      </Row>
      <Row className={classes.docRow} justify='space-between'>
        <Text style={{ paddingLeft: '10%', fontFamily: preferred_document_font }}>Plaintiffs, </Text>
        <Text style={{ fontFamily: preferred_document_font }}>CASE NO.: {caseNumber}</Text>
      </Row>
      <Row className={classes.docRow}>
        <Text style={{ fontFamily: preferred_document_font }}>v.</Text>
      </Row>
      <Row className={classes.docRow}>
        <Text style={{ fontFamily: preferred_document_font }}>{defendant?.toUpperCase()},</Text>
      </Row>
      <Row className={classes.docRow}>
        <Col span={8} ><Text style={{ fontFamily: preferred_document_font }}>Defendant.</Text></Col>
      </Row>
      <Row className={classes.docRow} justify='start'>
        <Col>
          <Text>____________________________________________/</Text>
        </Col>
      </Row>
      {!editNoticeHeading ?
        <Row className={classes.docRow} justify='center'>
          <Tooltip title={!editNoticeHeading ? 'Click to edit' : ''} placement="topLeft">
            <Text style={{ fontFamily: preferred_document_font }} className={classes.notice} onClick={toggleEditNoticeHeading}>
              {noticeHeading}
            </Text>
          </Tooltip>
        </Row> :
        <div>
          <Form.Item name="noticeHeading">
            <div className="custom-form-label"></div>
            <Input
              value={updatedNoticeHeading ? updatedNoticeHeading : noticeHeading}
              placeholder="Enter Notice Heading"
              onChange={(e) =>
                setUpdatedNoticeHeading(e.target.value)
              }
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: 'end', marginBottom: "10px" }}>
            <Button onClick={toggleEditNoticeHeading} type='primary'> Cancel</Button>
            <Button onClick={handleUpdateNoticeHeading} style={{ marginLeft: '0.75rem' }} type='primary'>Save</Button>
          </div>
        </div>
      }
      {!editNoticeSubheading ?
        <Row className={classes.docRow} justify='start'>
          <Tooltip title={!editNoticeSubheading ? 'Click to edit' : ''} placement="topLeft">
            <Text style={{ textAlign: 'start', fontFamily: preferred_document_font }} className={classes.docRow} onClick={toggleEditNoticeSubheading}>
              {noticeSubheading}
              {/* NOTICE OF SERVICE OF PLAINTIFF'S RESPONSE TO DEFENDANT'S{" "}
          {document === "rfa"
            ? " REQUEST FOR ADMISSIONS "
            : document === "rfp"
            ? " REQUEST FOR PRODUCTION "
            : " SET OF INTERROGATORIES "} */}
            </Text>
          </Tooltip>
        </Row> :
        <div>
          <Form.Item name="noticeSubheading">
            <div className="custom-form-label"></div>
            <Input
              value={updatedNoticeSubheading ? updatedNoticeSubheading : noticeSubheading}
              placeholder="Enter Notice Heading"
              onChange={(e) =>
                setUpdatedNoticeSubheading(e.target.value)
              }
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: 'end', marginBottom: "10px" }}>
            <Button onClick={toggleEditNoticeSubheading} type='primary'> Cancel</Button>
            <Button onClick={handleUpdateNoticeSubheading} style={{ marginLeft: '0.75rem' }} type='primary'>Save</Button>
          </div>
        </div>
      }
      <Col offset={8}>
        <Space direction="vertical" size="small">
          {/* {user?.signature && <div dangerouslySetInnerHTML={{__html: user.signature }} ></div>} */}
        </Space>
      </Col>
      {!editCertificateHeading ?
        <Row className={classes.docRow} justify='center'>
          <Tooltip title={!editCertificateHeading ? 'Click to edit' : ''} placement="topLeft">
            <Text style={{ margin: '0 auto', fontFamily: preferred_document_font }} className={classes.notice} onClick={toggleEditCertificateHeading}>
              {certificateHeading}
            </Text>
          </Tooltip>
        </Row> :
        <div>
          <Form.Item name="certificateHeading">
            <div className="custom-form-label"></div>
            <Input
              value={updatedCertificateHeading ? updatedCertificateHeading : certificateHeading}
              placeholder="Enter Certificate Heading"
              onChange={(e) =>
                setUpdatedCertificateHeading(e.target.value)
              }
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: 'end', marginBottom: "10px" }}>
            <Button onClick={toggleEditCertificateHeading} type='primary'> Cancel</Button>
            <Button onClick={handleUpdateCertificateHeading} style={{ marginLeft: '0.75rem' }} type='primary'>Save</Button>
          </div>
        </div>
      }












      {!editCertificateSubHeading ?
        <Row className={classes.docRow} justify='start'>
          <Tooltip title={!editCertificateSubHeading ? 'Click to edit' : ''} placement="topLeft">
            {/* <Text style={{ margin: '0 auto', fontFamily: preferred_document_font }} className={classes.notice} >

            </Text> */}

            <Paragraph style={{ textAlign: 'start' }} onClick={toggleEditCertificateSubHeading}>
              <span style={{ fontFamily: preferred_document_font }}>
                {certificateSubHeading
                  .replace('{{defendant}}', caseData.defendant ? caseData.defendant : '________')
                  .replace('{{email}}', emailList ? emailList : '_______')
                  .replace('{{due_date}}', caseData.dueDate ? `${new Date(caseData.dueDate).getDate()}th of ${formatter.format(new Date(caseData.dueDate))}, ${new Date(caseData.dueDate).getFullYear()}` : '_______')
                }</span>
            </Paragraph>

          </Tooltip>
        </Row> :
        <div>
          <Form.Item name="certificateSubHeading">
            <div className="custom-form-label"></div>
            <Input
              value={updatedCertificateSubHeading ? updatedCertificateSubHeading : certificateSubHeading}
              placeholder="Enter Certificate-Sub Heading"
              onChange={(e) =>
                setUpdatedCertificateSubHeading(e.target.value)
              }
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: 'end', marginBottom: "10px" }}>
            <Button onClick={toggleEditCertificateSubHeading} type='primary'> Cancel</Button>
            <Button onClick={handleUpdateCertificateSubHeading} style={{ marginLeft: '0.75rem' }} type='primary'>Save</Button>
          </div>
        </div>
      }










      {/* <Row className={classes.docRow}>
        <Text style={{ margin: '0 auto', fontFamily: preferred_document_font }}><u><b>CERTIFICATE OF SERVICE</b></u></Text>



      
      </Row> */}
      <Col offset={14} style={{ fontFamily: preferred_document_font }}>
        {/* Right aligned and add signatures underneath */}
        {/* <Text>Respectfully submitted, </Text> */}
        {user?.signature && <div dangerouslySetInnerHTML={{ __html: user.signature }} className={classes.noSpace}></div>}
      </Col>
    </>
  );
};

export default Header;
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { fetchAllUsers } from "../../actions/users";
import LandingCard from "./LandingCard";
import * as accessControlHelper from '../../utils/accessControlHelper';

const Landing = () => {
  const dispatch = useDispatch(fetchAllUsers());
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(fetchAllUsers(user));
  }, [dispatch]);
  const users = useSelector((state) => state.users);
  const cases = useSelector((state) => state.cases);
  const suffix = "/ " + user.user.att_limit;
  const att = Array.isArray(users)
    ? users.filter((x) => x.role_id.slug === accessControlHelper.ATTORNEY_ROLE)
    : [];

  const firms = Array.isArray(users)
    ? users.filter((x) => x.role_id.slug === accessControlHelper.CASE_ADMIN_ROLE)
    : [];

  const clients = Array.isArray(users)
    ? users.filter((x) => x.role_id.slug === accessControlHelper.CLIENT_ROLE)
    : [];

  return (
    <Row className="dashboard" gutter={16}>
      {user.user.role === accessControlHelper.CASE_ADMIN_ROLE && (
        <Col span={6}>
          <LandingCard img={'./img/attorney.png'} title={"Attorney's Limit"} count={`${att.length}/${user?.user?.att_limit}`} />
        </Col>
      )}
      {user.user.role === accessControlHelper.SUPER_ADMIN_ROLE && (
        <Col span={6}>
          <LandingCard img={'./img/firm.png'} title={'Total Firms'} count={firms.length} />
        </Col>
      )}
      {user.user.role === accessControlHelper.SUPER_ADMIN_ROLE && (
        <Col span={6}>
          <LandingCard img={'./img/attorney.png'} title={'Total Attorneys'} count={att.length} />
        </Col>
      )}
      <Col span={6}>
        <LandingCard img={'./img/client-ico.png'} title={'Total Clients'} count={clients.length} />
      </Col>
      {user.user.role === accessControlHelper.SUPER_ADMIN_ROLE ? null : (
        <Col span={6}>
          <LandingCard img={'./img/case-icon.png'} title={'Total Cases'} count={cases.length} />
        </Col>
      )}
    </Row>
  );
};
export default Landing;

import axios from "axios";
import {
  UPDATE_USER,
  DELETE_USER,
  FETCH_ALL_USER,
  GET_ERRORS,
  RESET_PASSWORD,
  RESET_PASSWORD_TOKEN,
  UPDATE_USER_AUTH,
  GET_ALL_COMMENTS,
  CREATE_COMMENT,
  ADD_USER_PHONE,
} from "./types";
// Register User

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

export const fetchAllUsers = (user) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/users/${user.user.id}/${user.user.role}`)
    .then((res) => {
      dispatch({ type: FETCH_ALL_USER, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data ?? 'Error getting all users',
      })
    );
};

export const deleteUsers = (id) => (dispatch) => {
  axios
    .delete(`${BASE_URL}/api/users/${id}`)
    .then((res) => {
      dispatch({ type: DELETE_USER, payload: id });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const updateUser = (id, userInfo) => (dispatch) => {
  //console.log("in updateuser function in user.js file the user received "+JSON.stringify(userInfo));
  axios
    .patch(`${BASE_URL}/api/users/${id}`, userInfo)
    .then((res) => {
      dispatch({ type: UPDATE_USER, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const addUserPhone = (id, data) => (dispatch) => {
  axios
    .patch(`${BASE_URL}/api/users/phone/${id}`, data)
    .then((res) => {
      dispatch({ type: ADD_USER_PHONE, payload: res.data });

      alert("Phone number updated successfully");
      window.location.reload();
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const enableUser = (id, userInfo) => (dispatch) => {
  axios
    .patch(`${BASE_URL}/api/users/enable/${id}`, userInfo)
    .then((res) => {
      dispatch({ type: UPDATE_USER, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const userEnabled = (id) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/users/userEnabled/${id}`)
    .then((res) => {
      dispatch({ type: UPDATE_USER_AUTH, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const resetPassword = (user) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/users/password-reset`, user)
    .then((res) => {
      dispatch({ type: RESET_PASSWORD, payload: res.data });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

export const resetPasswordToken = (user) => (dispatch) => {
  const urlHit = `${BASE_URL}/api/users/password-reset/` + user.userId + "/" + user.token;
  delete user.userId;
  delete user.token;
  axios
    .post(urlHit, user)
    .then((res) => {
      // console.log(res.data);
      dispatch({ type: RESET_PASSWORD_TOKEN, payload: res.data });
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const fetchQuestionComments = (data) => (dispatch) => {
  axios
    .get(`${BASE_URL}/api/comments/getComments/` + data.document + "/" + data.question)
    .then((res) => {
      dispatch({ type: GET_ALL_COMMENTS, payload: res.data });
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: err?.response?.data,
      });
    });
};

export const storeQuestionComments = (data) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/comments/`, data)
    .then((res) => {
      dispatch({ type: CREATE_COMMENT, payload: res.data });
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

import * as api from "../api";

export const getCases = (user_id) => async (dispatch) => {
  try {
    const { data } = await api.fetchCases(user_id);
    dispatch({ type: "FETCH_ALL", payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const createCase = (newCase) => async (dispatch) => {
  try {
    const { data } = await api.createCase(newCase);

    dispatch({ type: "CREATE", payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const updateCase = (id, caseInfo) => async (dispatch) => {
  try {
    const { data } = await api.updateCase(id, caseInfo);

    dispatch({ type: "UPDATE", payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const updateDocsNames = (id, docsNames) => async (dispatch) => {
  try {
    const { data } = await api.updateCaseServiceTableDocs(id, docsNames);

    dispatch({ type: "UPDATE_CASE_DOCS_NAMES", payload: data });
    alert("Document(s) name(s) updated successfully");
    window.location.reload();
  } catch (error) {
    console.log(error);
  }
};

export const deleteCase = (id) => async (dispatch) => {
  try {
    await api.deleteCase(id);

    dispatch({ type: "DELETE", payload: id });
  } catch (error) {
    console.log(error);
  }
};

export const updateCaseHeadings = (case_id, docId, payload) => async (dispatch) => {
  try {
    const { data } = await api.updateCaseHeadings(case_id, docId, payload);

    // dispatch({ type: "UPDATE_CASE_HEADINGS", payload: data });
  } catch (error) {
    console.log(error);
  }
};

/* export const getDocument = () => async (dispatch) => {
          try {
                    const { data } = await api.fetchDocument();
                    dispatch({ type: "DOCUEMNT", payload: data });
          } catch (error) {
                    console.log(error);
          }
}; */

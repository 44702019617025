import { useState, useEffect, createContext, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import { getCases } from "./actions/cases";
import BasicLayout from "./layouts/BasicLayout";
import AddNew from "./pages/AddNew/AddNew";
import Case from "./pages/Case/Case";
import Home from "./pages/Discovery/Home";
import Landing from "./pages/Landing/Landing";
import AddQuestions from "./components/AddQuestions/AddQuestions";
import DocumentPreview from "./pages/DocumentPreview/DocumentPreview";
import Register from "./components/Auth/Register";
import Login from "./components/Auth/Login";
import NewUser from "./components/Users/NewUser";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import Users from "./components/Users/Users";
import ResetPassword from "./components/Users/ResetPassword";
import Profile from "./components/Users/Profile";

import store from "./store";
import AddResponse from './pages/AddResponse/AddResponse';
import NotFound404 from "./pages/404/NotFound404";
import NewResponse from "./components/Response/NewResponse";
import DisplayResponse from "./components/Response/DisplayResponse";
import UpdateResponse from "./components/Response/UpdateResponse";
import DisplayObjection from "./components/Objections/DisplayObjection";
import NewObjection from "./components/Objections/NewObjection";
import UpdateObjection from "./components/Objections/UpdateObjection";
import Inquiries from "./pages/Inquireies/Inquireies";
import AccessControl from "./pages/AccessControl/AccessControl";
import AddNewRole from "./components/AddNewRole/AddNewRole";

import * as accessControlHelper from "./utils/accessControlHelper";
import TechSupport from "./pages/TechSupport/TechSupport";
import AddTicket from "./components/AddTicket/AddTicket";
import UpdateTicket from "./components/UpdateTicket/UpdateTicket";
import EditQuestions from "./components/AddQuestions/EditQuestions";

const AppContext = createContext();
export const useGlobalContext = () => {
  return useContext(AppContext);
};

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
  }
}

function App() {
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch(getCases(user));
  /* const dispatch2 = useDispatch(userEnabled(user.user.id));

  useEffect(() => {
      console.log(user.user.id);
      dispatch2(userEnabled(user.user.id));
  }, [dispatch2]);
  const isEnabled = useSelector((state) => state);
  console.log(isEnabled); */

  useEffect(() => {
    dispatch(getCases(user));
  }, [user, dispatch]);

  const [responseId, setResponseId] = useState(null);
  const [list, setList] = useState([]);
  const [caseInfo, setCaseInfo] = useState({
    matterNumber: "",
    caseName: "",
    user_id: "",
    client_id: "",
    attorney_id: "",
    plaintiffOrDefendant: "",
    plaintiff: "",
    defendant: "",
    court: "",
    case: "",
    ocName1: "",
    ocEmail1: "",
    ocName2: "",
    ocEmail2: "",
    ocName3: "",
    ocEmail3: "",
    ocName4: "",
    ocEmail4: "",
    ocName5: "",
    ocEmail5: "",
    contactInfoName: "",
    contactInfoEmail: "",
    dueDate: "",
    documents: [],
  });
  const [currentId, setCurrentId] = useState("");

  const removeItem = (id) => {
    setList(list.filter((item) => item.id !== id));
  };

  return (
    <BrowserRouter>
      <AppContext.Provider
        value={{
          removeItem,
          list,
          setList,
          caseInfo,
          setCaseInfo,
          currentId,
          setCurrentId,
          store,
        }}
      >
        <BasicLayout>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/users" element={<Users />} />
            {accessControlHelper.VerifyAccess(accessControlHelper.DASHBOARD) ? (
              <Route path="/dashboard" element={<Landing />} />
            ) : (
              ""
            )}
            {accessControlHelper.VerifyAccess(accessControlHelper.VIEW_CASES) ? (
              <Route path="/cases" element={<Home />} />
            ) : (
              ""
            )}
            {accessControlHelper.VerifyAccess(accessControlHelper.SHOW_CASES_ON_DASHBOARD) ? (
              <Route path="/dashboard" element={<Home />} />
            ) : (
              ""
            )}

            {/* {accessControlHelper.VerifyAccess(accessControlHelper.SHOW_CASES_ON_DASHBOARD) ? (
            <Route path="/inquiries" element={<Inquiries />} />
          ) : (
            ""
          )} */}
            {accessControlHelper.VerifyAccess(accessControlHelper.VIEW_CASES) ? (
              <Route path="/cases" element={<Navigate replace to="/" />} />
            ) : null}
            <Route path="/dashboard/:id" element={<Case />} />
            <Route path="/new" element={<AddNew />} />
            <Route path="/responses" element={<DisplayResponse />} />
            <Route path="/responses/new" element={<NewResponse />} />
            <Route path="/responses/update/:id" element={<UpdateResponse />} />
            <Route path="/objections" element={<DisplayObjection />} />
            <Route path="/objections/new" element={<NewObjection />} />
            <Route path="/objections/update/:id" element={<UpdateObjection />} />
            <Route path="/new/:id" element={<AddNew />} />
            <Route path="/add/:document/:id" element={<AddQuestions />} />
            <Route path="/edit-questions/:document/:id" element={<EditQuestions />} />
            <Route path="/edit/:document/:id" element={<AddResponse />} />
            <Route path="/view/:document/:id" element={<DocumentPreview />} />
            <Route path="/users/new" element={<NewUser />} />
            <Route path="/users/edit/:id" element={<NewUser />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route
              path="/reset-password/:userId/:token"
              element={<ResetPassword />}
            />
            <Route path="/profile" element={<Profile />} />

            <Route path="/access-control" element={<AccessControl />} />
            <Route path="/access-control/new/:id?" element={<AddNewRole />} />


            <Route path="/tech-support" element={<TechSupport />} />
            <Route path="/tech-support/new" element={<AddTicket />} />
            <Route path="/tech-support/new/:id?" element={<UpdateTicket />} />


            <Route path="*" element={<NotFound404 />} exact />
          </Routes>
        </BasicLayout>
      </AppContext.Provider>
    </BrowserRouter>
  );
}

export default App;
import axios from "axios";

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

const url = `${BASE_URL}/api/cases`;

export const fetchCases = (user_id) => axios.get(`${url}/${user_id.user.id}/${user_id.user.role}`);

export const createCase = (newCase) => axios.post(url, newCase);

export const updateCase = (id, updatedCase) => axios.patch(`${url}/${id}`, updatedCase);

export const updateCaseServiceTableDocs = (id, caseInfo) => axios.patch(`${url}/docs/${id}`, caseInfo);

export const deleteCase = (id) => axios.delete(`${url}/${id}`);

export const updateCaseHeadings = (case_id, docId, data) => axios.patch(`${url}/document/${case_id}/${docId}`, data);

export const fetchPermissions = () => axios.get(`${BASE_URL}/api/access-control/permissions`);
export const fetchRoles = (role_id) => axios.get(`${BASE_URL}/api/access-control/role/${role_id}`);
export const createRole = (newRole) => axios.post(`${BASE_URL}/api/access-control/role`, newRole);
export const updateRole = (id, updatedRole) => axios.patch(`${BASE_URL}/api/access-control/role/${id}`, updatedRole);


export const fetchTickets = (ticket_id) => axios.get(`${BASE_URL}/api/tickets/${ticket_id}`);
export const createTicket = (newTicket) => axios.post(`${BASE_URL}/api/tickets/`, newTicket);
export const updateTicket = (id, updatedTicket) => axios.patch(`${BASE_URL}/api/tickets/${id}`, updatedTicket);
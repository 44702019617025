
import { NavLink } from "react-router-dom";

function NotFound404() {
  return (
    <div className="ant-card">
      <div>
        <h3 style={{ color: "red", fontSize: 100 }}>404</h3>
      </div>
      <div style={{ marginTop: 20 }}>
        <h2>Page Not Found</h2>
      </div>
      <div style={{ marginTop: 5 }}>
        <NavLink to="/">
          <button className="ant-btn">Home</button>
        </NavLink>
      </div>
    </div>
  );
}

export default NotFound404;

import { useState, useEffect } from "react";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Radio, Space, Typography, Switch, Divider, Button } from "antd";
import { CloseOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useParams } from "react-router-dom";
import { updateCase } from "../../actions/cases";
import * as accessControlHelper from '../../utils/accessControlHelper';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'


const ObjectionsResponsesSelector = ({
  document,
  question,
  id,
  editMode = false,
  setEditMode,
  item,
  presetTab,
  response
}) => {
  const { TabPane } = Tabs;
  const [currentTab, setCurrentTab] = useState(!!presetTab ? presetTab : 'Responses');
  const [currentResponse, setCurrentResponse] = useState(response);
  const [reviewable, setReviewable] = useState(item?.isReviewable ?? false);
  const [responses, setResponses] = useState([]);
  const [objections, setObjections] = useState([]);
  const [resValue, setResValue] = useState({
    content: response ?? ''
  });
  const user = useSelector((state) => state.auth);
  const preferred_document_font = user?.document_font ?? "arial";
  const dispatch = useDispatch();
  const { id: caseId } = useParams();
  const [caseUpdateInflight, setCaseUpdateInFlight] = useState(false);
  const radio_disabled = (user.user.role === accessControlHelper.SUPER_ADMIN_ROLE || user.user.role === accessControlHelper.CASE_ADMIN_ROLE || user.user.role === accessControlHelper.ATTORNEY_ROLE) ? false : true;

  const currentCase = useSelector((state) =>
    id ? state.cases.find((item) => item._id === caseId) : null
  );

  // let userId = user?.user?.id;
  // if(user?.user?.type == 'admin-cas') {
  //   userId = user?.user?.id
  // } else if(user?.user?.type == 'admin-sup'){
  //   userId = user?.user?.type
  // } else {
  //   userId = user?.user?.id
  // }


  let userIdForObjection = '';
  let userAccessForObjection = '';
  if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_ALL_OBJECTIONS)) {
    userIdForObjection = user?.user?.role;
    userAccessForObjection = "All";
  } else if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_OWN_AND_SUBORDINATES_OBJECTIONS)) {
    userIdForObjection = user?.user?.id;
    userAccessForObjection = "Own-and-subordinates";
  } else {
    userIdForObjection = user?.user?.id;
    userAccessForObjection = "Own";
  }

  let userIdForResponses = '';
  let userAccessForResponses = '';
  if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_ALL_RESPONSES)) {
    userIdForResponses = user?.user?.role;
    userAccessForResponses = "All";
  } else if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_OWN_AND_SUBORDINATES_RESPONSES)) {
    userIdForResponses = user?.user?.id;
    userAccessForResponses = "Own-and-subordinates";
  } else {
    userIdForResponses = user?.user?.id;
    userAccessForResponses = "Own";
  }

  const description = resValue?.content ?? '';

  useEffect(() => {
    axios.get(`${BASE_URL}/api/responses/${userIdForResponses}/${userAccessForResponses}`)
      .then((res) => setResponses(res.data))
      .catch((error) => console.log(error))
  }, [responses.length])

  useEffect(() => {
    axios.get(`${BASE_URL}/api/objections/${userIdForObjection}/${userAccessForObjection}`)
      .then((res) => setObjections(res.data))
      .catch((error) => console.log(error))
  }, [objections.length])

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setCurrentResponse(data);
    setResValue({ ...resValue, content: data });
  }

  const markAsReviewable = (e) => {
    setReviewable(e);
  };
  const addResponse = (response) => {
    const selectedResponse = response;
    setResValue({ ...resValue, content: resValue.content + " " + selectedResponse.content });
  };

  const addObjection = (objection) => {
    const selectedObjection = objection;
    setResValue({ ...resValue, content: resValue.content + " " + selectedObjection.content });
  };

  const handleSave = async () => {
    if (currentTab === 'Custom') {
      console.log('do custom stuff');
    }

    setCaseUpdateInFlight(true);
    const questions = currentCase.documents.filter(
      (item) => item.documentType === document
    )[0]?.questions;

    const updatedQuestions = (questions ?? []).map(question => {
      if (question?._id === id) {
        return {
          ...question,
          isAnswered: !!resValue?.content ? true : false,
          isReviewable: reviewable,
          response: currentTab === 'Custom' ? currentResponse : resValue?.content
        }
      }
      return question;
    });

    const editedDocuments = currentCase.documents.map((item) => {
      if (item.documentType === document) {
        return { documentType: document, questions: updatedQuestions };
      }
      return item;
    });

    const caseInfo = { ...currentCase, documents: editedDocuments };

    await dispatch(updateCase(caseId, caseInfo));
    setCaseUpdateInFlight(false);
    setEditMode(false);
  }

  const handleTabChange = (event) => {
    setCurrentTab(event)
  }

  return (
    <>
      <Tabs activeKey={currentTab} onChange={handleTabChange}>
        <TabPane tab="Responses" key="Responses">
          {/* <Radio.Group buttonStyle="solid" value={resValue} onChange={addResponse}>
            <Space direction="vertical">
              {responses.map((resp) => {
                const { assignTo, title, _id } = resp;
                if (assignTo.includes(document.toUpperCase())) {
                  return (
                    <Radio key={_id} value={resp} disabled={radio_disabled}>
                      {title}
                    </Radio>
                  )
                } else {
                  return '';
                }
              })}
            </Space>
          </Radio.Group> */}
          {responses.map((resp) => {
            const { assignTo, title, _id } = resp;
            if (assignTo.includes(document.toUpperCase())) {
              return (
                <p key={_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Button onClick={() => addResponse(resp)} type='primary' size="small" ButtonHTMLType="button">
                    <PlusOutlined />
                  </Button>
                  <span>{title}</span>
                </p>
              );
            } else {
              return null;
            }
          })}
        </TabPane>
        <TabPane tab="Objections" key="Objections">
          {/* <Radio.Group buttonStyle="solid" value={resValue} onChange={addObjection}>
            <Space direction="vertical">
              {objections.map((obj) => {
                const { assignTo, title, _id } = obj;
                if (assignTo.includes(document.toUpperCase())) {
                  return (
                    <Radio key={_id} value={obj} disabled={radio_disabled}>
                      {title}
                    </Radio>
                  )
                } else {
                  return '';
                }
              })}
            </Space>
          </Radio.Group> */}


          {objections.map((obj) => {
            const { assignTo, title, _id } = obj;
            if (assignTo.includes(document.toUpperCase())) {
              return (
                // <p key={_id}>
                //   <PlusOutlined onClick={() => addObjection(obj)} />
                //   {title}
                // </p>

                <p key={_id} style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Button onClick={() => addObjection(obj)} type='primary' size="small" ButtonHTMLType="button">
                    <PlusOutlined />
                  </Button>
                  <span>{title}</span>
                </p>
                
              );
            } else {
              return null;
            }
          })}
        </TabPane>
        <TabPane tab="Custom" key="Custom" value={currentResponse}>
          <CKEditor
            editor={ClassicEditor}
            name="content"
            data={description}
            onChange={handleChange}
          />
        </TabPane>
      </Tabs>
      {(!accessControlHelper.VerifyAccess(accessControlHelper.MARK_RESPONSES_AS_REVIEWABLE)) ? null : (
        <>
          <Divider />
          <div style={{ display: 'flex' }}>
            <div>
              <Typography keyboard="true">
                Client Feedback &nbsp;
              </Typography>
              <Switch
                onChange={(e) => markAsReviewable(e)}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={reviewable}
              />
            </div>
            {editMode & currentTab === 'Custom' && (
              <div style={{ marginLeft: 'auto' }}>
                <Button onClick={() => setEditMode(false)} type='primary' disabled={caseUpdateInflight}>Cancel</Button>
                <Button onClick={handleSave} loading={caseUpdateInflight} disabled={caseUpdateInflight} style={{ marginLeft: '0.75rem' }} type='primary'>Save</Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ObjectionsResponsesSelector;

import { useEffect, useState } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Table, Tag, Space, Popconfirm, Button, Card } from "antd";
import ReactHtmlParser from 'html-react-parser';
import { useSelector } from 'react-redux';
import * as accessControlHelper from '../../utils/accessControlHelper';

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'
const DisplayObjection = () => {
  const [objections, setObjections] = useState([]);
  const user = useSelector((state) => state.auth);


  const can_edit = accessControlHelper.VerifyAccess(accessControlHelper.EDIT_OBJECTIONS);
  const can_delete = accessControlHelper.VerifyAccess(accessControlHelper.DELETE_OBJECTIONS);


  let userId = '';
  let userAccess = '';
  if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_ALL_OBJECTIONS)) {
    userId = user?.user?.role;
    userAccess = "All";
  } else if (accessControlHelper.VerifyAccess(accessControlHelper.VIEW_OWN_AND_SUBORDINATES_OBJECTIONS)) {
    userId = user?.user?.id;
    userAccess = "Own-and-subordinates";
  } else {
    userId = user?.user?.id;
    userAccess = "Own";
  }

  const deleteObjection = (id) => {
    axios.delete(`${BASE_URL}/api/objections/${id}`)
      .then(() =>
        axios.get(`${BASE_URL}/api/objections/${userId}`)
          .then((res) => setObjections(res.data))
          .catch((error) => console.log(error))
      );
  }

  useEffect(() => {
    axios.get(`${BASE_URL}/api/objections/${userId}/${userAccess}`)
      .then((res) => setObjections(res.data))
      .catch((error) => console.log(error))

  }, [objections.length])


  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => (
        <>{text}</>
      ),
    },
    {
      title: "Content",
      dataIndex: "content",
      key: "content",
      render: (text, record) => (
        <>{ReactHtmlParser(text)}</>
      ),
    },


    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "assignTo",
      render: (text, record) => (
        <>{text.map((tex) => {
          return (
            <Tag color="green" key={tex}>
              <Link to={''}>{tex.toUpperCase()}</Link>
            </Tag>
          )
        })}</>
      ),
    },
    {
      title: "Owner",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <>{ReactHtmlParser(text)}</>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "_id",
      render: (_id, userId, record) => {
        return (
          <Space size="middle">

            {(can_edit) &&
              <Link to={`/objections/update/${_id}`}>
                <EditOutlined />
              </Link>
            }
            {(can_delete) &&
              <Popconfirm
                title="Are you sure?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteObjection(_id)}
              >
                <DeleteOutlined />
              </Popconfirm>
            }
          </Space>
        );
      },
    },
  ];

  const tableData = objections.map((obj) => {
    const { title, content, assignTo, creator, _id, userId, name } = obj;
    return {
      key: _id,
      title,
      content,
      assignTo,
      creator,
      _id,
      userId,
      name
    };
  });

  return (

    <Card hoverable>
      <div style={{ marginBottom: 20, display: "flex", justifyContent: "flex-end" }}>
        {accessControlHelper.VerifyAccess(accessControlHelper.ADD_OBJECTIONS) && (
          <Link to={"/objections/new"}>
            <Button type="primary">Add New Objection</Button>
          </Link>
        )}
      </div>
      <Table columns={columns} dataSource={tableData} />
    </Card>
  )

}

export default DisplayObjection

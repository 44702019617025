import TicketsList from "../../components/TicketsList/TicketsList";

const TechSupport = () => {
    return (
        <>
            <TicketsList />
        </>
    );
};

export default TechSupport;
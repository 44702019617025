
import { useDispatch, useSelector } from "react-redux";
import { Table, Tag, Space, Popconfirm, message } from "antd";
import moment from "moment";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { deleteCase } from "../../actions/cases";
import { useGlobalContext } from "../../App";
import * as accessControlHelper from '../../utils/accessControlHelper';

const CaseList = () => {
  const cases = useSelector((state) => state.cases);
  const dispatch = useDispatch();
  const { setCurrentId } = useGlobalContext();

  const can_edit = accessControlHelper.VerifyAccess(accessControlHelper.EDIT_CASES);
  const can_delete = accessControlHelper.VerifyAccess(accessControlHelper.DELETE_CASES);
  const can_view_case_documents = accessControlHelper.VerifyAccess(accessControlHelper.VIEW_CASE_DOCUMENTS);

  const user = useSelector((state) => state.auth);
  const removeCase = (id) => {
    dispatch(deleteCase(id));
    message.success("Case has been deleted");
  };

  const columns = [
    {
      title: "Matter #",
      dataIndex: "matterNumber",
      key: "matterNumber",
      render: (text, record) => <Link to={`/dashboard/${record._id}`}>{text}</Link>,
    },
    {
      title: "Case Name",
      dataIndex: "caseName",
      key: "caseName",
      render: (text, record) => <Link to={`/dashboard/${record._id}`}>{text}</Link>,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text) => <p>{moment(text).fromNow()}</p>,
      sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
    },
    {
      title: "Discovery",
      dataIndex: "tags",
      key: "discovery",
      render: (tags, caseData) => (
        <>
          {tags.map((tag) => {
            const { key } = caseData;
            if (can_view_case_documents) {
              return (
                <Tag color="green" key={tag}>
                  <Link to={`/view/${tag}/${key}`}>{tag.toUpperCase()}</Link>
                </Tag>
              );
            }
          })}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "_id",
      render: (_id) => (
        <Space size="middle">
          {/* {user.user.type === "admin-sup" ||
            user.user.type === "admin-cas" ||
            user.user.type === "admin-att" ? (
            <Link to={`/new/${_id}`}>
              <EditOutlined onClick={() => setCurrentId(_id)} />
            </Link>
          ) : (
            []
          )}
          {user.user.type === "admin-att" || user.user.type === "admin-cas" ? (
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => removeCase(_id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          ) : (
            []
          )} */}

          {(can_edit) &&
            <Link to={`/new/${_id}`}>
              <EditOutlined />
            </Link>

          }
          {(can_delete) &&
            <Popconfirm
              title="Are you sure?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => removeCase(_id)}
            >
              <DeleteOutlined />
            </Popconfirm>
          }
        </Space>
      ),
    },
  ];

  const columnsForClientRole = [
    {
      title: "Matter #",
      dataIndex: "matterNumber",
      key: "matterNumber",
      render: (text, record) => <Link to={`/dashboard/${record._id}`}>{text}</Link>,
    },
    {
      title: "Case Name",
      dataIndex: "caseName",
      key: "caseName",
      render: (text, record) => <Link to={`/dashboard/${record._id}`}>{text}</Link>,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (text) => <p>{moment(text).fromNow()}</p>,
      sorter: (a, b) => moment(a.dueDate).unix() - moment(b.dueDate).unix(),
    },
  ];

  const tableDataClient = Array.isArray(cases)
    ? cases.map((caseInfo) => {
      // console.log("case info: ", caseInfo);
      const { matterNumber, caseName, dueDate, _id } = caseInfo;
      return {
        key: _id,
        matterNumber,
        caseName,
        dueDate,
        _id,
      };
    })
    : "";

  const tableData = Array.isArray(cases)
    ? cases.map((caseInfo) => {
      // console.log("case info: ", caseInfo);
      const { matterNumber, caseName, dueDate, _id, documents } = caseInfo;
      const documentArray =
        documents.length === 0
          ? [""]
          : documents.map((item) => item.documentType);
      return {
        key: _id,
        matterNumber,
        caseName,
        dueDate,
        tags: documentArray,
        _id,
      };
    })
    : "";


  return (
    <>
      {!cases.length ? (
        <Table columns={columns} />
      ) : (
        <>
          {user.user.role === accessControlHelper.CLIENT_ROLE ? (
            <Table
              columns={columnsForClientRole}
              dataSource={tableDataClient}
            />
          ) : (
            <Table columns={columns} dataSource={tableData} />
          )}
        </>
      )}
    </>
  );
};

export default CaseList;

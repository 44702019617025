export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const FETCH_ALL = "FETCH_ALL";
export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const FETCH_ALL_USER = "FETCH_ALL_USER";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_TOKEN = "RESET_PASSWORD_TOKEN";
export const UPDATE_USER_AUTH = "UPDATE_USER_AUTH";
export const GET_ALL_COMMENTS = "GET_ALL_COMMENTS";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const UPDATE_CASE_DOCS_NAMES = "UPDATE_CASE_DOCS_NAMES";
export const ADD_USER_PHONE = "ADD_USER_PHONE";

import  { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import {
          Form,
          Input,
          Button,
          Typography,
          Alert ,
          Modal,
        } from "antd";
import { useNavigate, useParams} from "react-router-dom";
import {  resetPassword, resetPasswordToken } from "../../actions/users";
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

const ResetPassword = () => {
    const { userId, token } = useParams();
    const [usering, setUsering] = useState({
        email: "",
        password: "",
        confirm_password: ""
    })
    const navigate = useNavigate();

    const userCurrent = useSelector((state) => state.auth);
    const currentUser = userCurrent.user;
    console.log(currentUser);

    const dispatch = useDispatch();

    const onChange = (e) => {
        if (typeof e === 'object'){
            e.persist();
            setUsering((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
        } else {
            setUsering((prevState) => ({ ...prevState, type: e }));
        }
    }

    const errors = useSelector((state) => state.errors);

    const messageModal = (message) => {
        Modal.success({
            title: 'Error',
            icon: <ExclamationCircleOutlined />,
            content: message,
            okText: 'OK',
          });
    };

    const success = (message) => {
        Modal.success({
        content: message,
        });
    };

    const onSubmit = () => {
        if(userId){
            if(usering.password !== usering.confirm_password){
                messageModal("Confirm password is not same as password");
            } else {
                const user = {
                    userId:userId,
                    token:token,
                    password: usering.password
                };
                dispatch(resetPasswordToken(user));
                success('Your password has been succesfully reset.');
                navigate('/login');
            }
            
        } else {
            const user = {
                email:usering.email
            };
            dispatch(resetPassword(user));
            success('An email with link to reset your password is sent to you on your email.');
            navigate('/login');
        }
       
    };
    
    if(userId){
        return (
            <Form  onFinish={onSubmit} labelCol={{ span: 10 }} wrapperCol={{ span: 4 }} >
                <Form.Item wrapperCol={{ /**offset: 10, span: 16**/ }}>
                    <Typography.Title>
                        Set Password
                    </Typography.Title>
                </Form.Item>
                <Form.Item name="password" label="Password">
                {errors.password ? <Alert message={errors.password} type="error" /> : ''}
                    <Input
                    onChange={onChange}
                    value={usering.password}
                    error={errors.password}
                    id="password"
                    type="password"
                    style={{  }} 
                    className={classnames("", {
                                invalid: errors.password
                            })}
                    />
                </Form.Item>
                <Form.Item name="confirm_password" label="Confirm Password">
                {errors.confirm_password ? <Alert message={errors.confirm_password} type="error" /> : ''}
                    <Input
                    onChange={onChange}
                    value={usering.confirm_password}
                    error={errors.confirm_password}
                    id="confirm_password"
                    type="password"
                    style={{ width: 500 }} 
                    className={classnames("", {
                                invalid: errors.confirm_password
                            })}
                    />
                </Form.Item>
                
                <Form.Item wrapperCol={{ /**offset: 10, span: 16 */ }}>
                    <Button type="primary" htmlType="submit">
                    Submit
                    </Button>
                </Form.Item>
            </Form>
        );
    } else {
        return (
            <Form  onFinish={onSubmit} labelCol={{ span: 10 }} wrapperCol={{ span: 4 }} >
                <Form.Item wrapperCol={{ /**offset: 10, span: 16 */ }}>
                    <Typography.Title>
                        Reset Password
                    </Typography.Title>
                </Form.Item>
                <Form.Item name="email" label="Email">
                {errors.email ? <Alert message={errors.email} type="error" /> : ''}
                    <Input
                    onChange={onChange}
                    value={usering.email}
                    error={errors.email}
                    id="email"
                    type="email"
                    placeholder="John.snow@gmail.com"
                    style={{ }} 
                    className={classnames("", {
                                invalid: errors.email
                            })}
                    />
                </Form.Item>
                
                <Form.Item wrapperCol={{ /**offset: 10, span: 16 */ }}>
                    <Button type="primary" htmlType="submit">
                    Submit
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

export default ResetPassword;
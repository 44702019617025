import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Form,
    Input,
    Checkbox,
    Button,
    Typography,
    Card,
    Modal,
    Radio,
    Select,
    Alert,
} from "antd";
import { createRole, updateRole } from "../../actions/accessControl";
import { useParams } from "react-router-dom";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createTicket, updateTicket } from "../../actions/TechSupport";

const { Option } = Select;

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

const AddTicket = () => {

    const dispatch = useDispatch();
    let { id } = useParams();
    const [editMode, setEditMode] = useState(false);

    const [formData, setFormData] = useState({
        subject: "",
        issue: "",
        status: "Pending",
        response: [],
    });
    // const handleChange = (e, editor) => {
    //     const data = editor.getData();
    //     console.log(data);
    //     setFormData({ ...formData, issue: data });
    // }

    const onStatusChange = (e) => {
        if (typeof e === "object") {
            e.persist();
            setFormData((formData) => ({ ...formData, [e.target.id]: e.target.value }));
        } else {
            setFormData((formData) => ({ ...formData, status: e }));
        }
    };

    useEffect(() => {
        if (id) {
            axios.get(`${BASE_URL}/api/tickets/${id}`).then(
                (response) => {
                    setFormData({ ...response.data.tickets })
                    setEditMode(true);
                }

            )
        }
    }, [])

    const [formError, setFormError] = useState({
        subjectErr: "",
        issueErr: "",
        statusErr: "",
    });

    const handleSubmit = () => {
        if (formData.subject === "") {
            setFormError({
                ...formError,
                subjectErr: "Subject is required.",
            });
        }
        if (formData.issue === "") {
            setFormError({
                ...formError,
                issueErr: "Issue is required.",
            });
        }
        if (formData.status === "") {
            setFormError({
                ...formError,
                statusErr: "Status is required.",
            });
        }
        if (formData.subject != "" && formData.issue != "" && formData.status != "") {

            // SUBMIT FORM HERE

            console.log(formData)
            if (!editMode) {
                dispatch(createTicket(formData));
                Modal.success({
                    content: "New Ticket created successfully",
                });
            } else {
                dispatch(updateTicket(id, formData));
                Modal.success({
                    content: "Ticket updated successfully",
                });
            }
            window.location.replace("/tech-support");
        }

    };

    return <Card>
        <Typography.Title>
            {editMode ? "Edit" : "New"} Ticket
        </Typography.Title>
        <Form
            layout="horizontal"
            className="discovery"
        >
            <Form.Item name="subject">
                <div className="custom-form-label">Subject</div>
                <Input
                    value={formData.subject}
                    onClick={() =>
                        setFormError({
                            ...formError,
                            subjectErr: "",
                        })
                    }
                    placeholder="Enter Ticket's Subject" onChange={(e) =>
                        setFormData({ ...formData, subject: e.target.value })
                    }
                />
                {formError.subjectErr ? (
                    <>
                        <div>
                            <p style={{ marginTop: 2, color: "red" }}>
                                {formError.subjectErr}
                            </p>
                        </div>
                    </>
                ) : null}
            </Form.Item>


            <Form.Item name="issue" >
                <div className="custom-form-label">Issue</div>
                <textarea
                    name="issue"
                    id="issue"
                    rows="10"
                    style={{ width: "100%" }}
                    onChange={e => setFormData({ ...formData, issue: e.target.value })}
                >
                    {formData.issue}
                </textarea>
                {formError.issueErr ? (
                    <>
                        <div>
                            <p style={{ marginTop: 2, color: "red" }}>
                                {formError.issueErr}
                            </p>
                        </div>
                    </>
                ) : null}
            </Form.Item>

            {/* <Form.Item name="status">
                <div className="custom-form-label">Status</div>
                <Select
                    placeholder="Select Status"
                    onChange={onStatusChange}
                    value={formData.status}
                    id="status"
                >
                    <Option key="1" selected={formData.status === "Pending" ? true : false} value="pending">Pending</Option>
                    <Option key="2" selected={formData.status === "In Progress" ? true : false} value="In Progress">In Progress</Option>
                    <Option key="3" selected={formData.status === "Complete" ? true : false} value="Complete">Complete</Option>
                </Select>

                {formError.statusErr ? (
                    <>
                        <div>
                            <p style={{ marginTop: 2, color: "red" }}>
                                {formError.statusErr}
                            </p>
                        </div>
                    </>
                ) : null}
            </Form.Item> */}

            <Form.Item name="submit-button">
                <div style={{ marginTop: 10, textAlign: "right", width: "100%" }}>
                    <Button htmlType="submit" type="primary" onClick={() => { handleSubmit() }}>
                        {editMode ? "Update" : "Add New Ticket"}
                    </Button>
                </div>
            </Form.Item>
        </Form >
    </Card >
}

export default AddTicket;
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";
// Register User

const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(`${BASE_URL}/api/users/register`, userData)
    .then((res) => {
      // localStorage.removeItem("jwtToken");
      // localStorage.removeItem("user");
      window.location.href = "./";
    }) // re-direct to login on successful register
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  console.log('logging in')
  axios
    .post(`${BASE_URL}/api/users/login`, userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      console.log(res)
      const { token } = res.data;

      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      
      localStorage.setItem("user", JSON.stringify(decoded) || []);
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      console.log('error block')
      console.log(err);
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });
    });
};
// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("user");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

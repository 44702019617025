import { useState } from "react";
import {
  Card, Typography, Space, Switch, Row, Button, Modal,
  Tooltip
} from "antd";
import { CloseOutlined, CheckOutlined, PlusSquareOutlined, UnderlineOutlined } from "@ant-design/icons";
import DrawerForm from "./Drawer.js";
import { useSelector, useDispatch } from "react-redux";
import classes from './AnsweredQuestions.module.css';
import HTMLReactParser from "html-react-parser";
import { updateCase } from "../../actions/cases";
import * as accessControlHelper from '../../utils/accessControlHelper';

const AnsweredQuestions = ({ questions, document, currentCase }) => {
  const dispatch = useDispatch();
  const [reviewable, setReviewable] = useState({});
  const [comentAble, setCommentAble] = useState(false);
  const [commentAbleQuestion, setCommentAbleQuestion] = useState({});
  const user = useSelector((state) => state.auth);
  const comment = (question) => {
    setCommentAble(true)
    setCommentAbleQuestion(question);
  };
  const drawerState = (st) => {
    setCommentAble(st);
  }


  const success = () => {
    Modal.success({
      content: `Question Updated Successfully`,
    });
  };

  return (
    <>
      {questions.length !== 0 ? (
        questions.map((question) => {
          const markAsUnanswered = (e) => {
            question.isAnswered = !e;
            console.log("question: ", question)
            console.log("document: ", document)
            console.log("currentCase: ", currentCase)


            const editedDocuments = currentCase.documents.map((item) => {
              if (item.documentType === document.documentType) {
                return {
                  ...item,
                  questions: item.questions.map((currentQuestion) => {
                    if (currentQuestion._id === question._id) {
                      return { ...currentQuestion, isAnswered: false };
                    }
                    return currentQuestion;
                  })
                };
              }
              return item;
            });

            const caseInfo = { ...currentCase, documents: editedDocuments };


            dispatch(updateCase(caseInfo._id, caseInfo));
            success();
          };

          const { _id, info, response } = question;
          let reviewAbleFirst = false;
          let id = '';
          if (question) {
            id = question._id;
            if (question.hasOwnProperty('isReviewable') && question.isReviewable == true) {
              reviewAbleFirst = true;
            } else {
              reviewAbleFirst = false;
            }
          }

          const markAsReviewable = (id, e) => {
            question.isReviewable = e;
            //setReviewable(e);
            setReviewable((prevState) => ({ ...prevState, [id]: e }));
          };

          return (
            <Card hoverable key={_id} style={{ marginBottom: '1rem' }}>
              <Space size="small" direction="vertical" >
                <Typography style={{ marginTop: 5 }}>{info}</Typography>
                <Typography style={{ fontWeight: "bold", textDecoration: "underline" }}> RESPONSE: </Typography>
                <Typography className={classes.responseChild} style={{ marginTop: 5 }}>{HTMLReactParser(response)}</Typography>
                {user.user.role === accessControlHelper.CLIENT_ROLE ? null :
                  <Row justify="space-between" style={{ marginTop: '1.25rem', alignItems: 'flex-end' }}>
                    <div className={classes.toggleable}>
                      <div >
                        {/* <Typography keyboard="true">
                          Mark as Unanswered &nbsp;
                        </Typography>
                        <Switch
                          onChange={(e) => markAsUnanswered(e)}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        /> */}
                        <Tooltip title='Mark this question as un-answered'>
                          <Button type='primary' className={classes.updateButton} onClick={markAsUnanswered}>Unanswered</Button>
                        </Tooltip>
                      </div>
                      <div>
                        <Typography keyboard="true">
                          Client Feedback &nbsp;
                        </Typography>
                        <Switch
                          onChange={(e) => markAsReviewable(question._id, e)}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          checked={
                            reviewable[question._id] == undefined
                              ? reviewAbleFirst
                              : reviewable[question._id]
                          }
                        />
                      </div>
                    </div>
                    {question.isReviewable != undefined && question.isReviewable == true ? (
                      <Button
                        type="primary"
                        icon={<PlusSquareOutlined />}
                        onClick={() => comment(question)}
                      >
                        Comment
                      </Button>
                    ) : null}
                  </Row>
                }
              </Space>
            </Card>
          );
        })
      ) : (
        <Typography>No questions answered yet</Typography>
      )}
      {comentAble ?
        <DrawerForm question={commentAbleQuestion} document={document} onchange={() => drawerState()} /> :
        null
      }
    </>
  );
};

export default AnsweredQuestions;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Input,
  DatePicker,
  Checkbox,
  Button,
  Typography,
  Card,
  Modal,
  Space,
  Radio,
  Select,
} from "antd";
import classes from './AddNewCase.module.css';
import {
  MinusCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";

import { createCase, updateCase } from "../../actions/cases";
import { useGlobalContext } from "../../App";
import { fetchAllUsers } from "../../actions/users";
import moment from "moment";
import * as accessControlHelper from '../../utils/accessControlHelper';

const { Option } = Select;

const AddNewCases = () => {
  const dispatch = useDispatch();
  const { caseInfo, setCaseInfo, currentId, setCurrentId } = useGlobalContext();
  const { id } = useParams();
  const [form] = Form.useForm();
  //dispatch(fetchAllUsers());
  const user = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(fetchAllUsers(user));
  }, [dispatch]);
  const users = useSelector((state) => state.users);
  const [formError, setFormError] = useState({
    plaintiffOrDefendantErr: "",
    matterNumberErr: "",
    caseNameErr: "",
    plaintiffErr: "",
    defendantErr: "",
    plaintiffsErr: "",
    defendantsErr: "",
    courtErr: "",
    caseNumberErr: "",
    ocTypeErr: "",
    ocInfoErr: "",
    contactInfoNameErr: "",
    contactInfoEmailErr: "",
    dueDateErr: "",
    documentsErr: "",
    client_idErr: "",
    attorney_idErr: "",
    // noticeHeadingErr: "",
  });
  const [addOpposingCouncel1, setAddOpposingCouncel1] = useState(false);
  const [addOpposingCouncel2, setAddOpposingCouncel2] = useState(false);
  const [addOpposingCouncel3, setAddOpposingCouncel3] = useState(false);
  const [addOpposingCouncel4, setAddOpposingCouncel4] = useState(false);
  const [addOpposingCouncel5, setAddOpposingCouncel5] = useState(false);
  const [docsValues, setDocsValues] = useState(null);

  /* const adminusers = users?.filter((x)=> {
    return x.type === "admin-cas";
  });

  const adminChildren = [];
  for (let i = 0; i < adminusers.length; i++) {
    adminChildren.push(<Option key={i} value={adminusers[i]._id}>{adminusers[i].name}</Option>);
  } */

  const clientusers = Array.isArray(users)
    ? users.filter((x) => x.role_id.slug === accessControlHelper.CLIENT_ROLE)
    : [];


  const clientChildren = [<Option value="">Select Client</Option>];
  for (let i = 0; i < clientusers.length; i++) {
    clientChildren.push(
      <Option key={i} value={clientusers[i]._id}>
        {clientusers[i].name}
      </Option>
    );
  }

  const currentCase = useSelector((state) =>
    id ? state.cases.find((item) => item._id === id) : null
  );

  const navigate = useNavigate();
  useEffect(() => {
    if (currentCase) {
      if (currentCase.ocName1 && currentCase.ocEmail1) {
        setAddOpposingCouncel1(!addOpposingCouncel1);
      }

      if (currentCase.ocName2 && currentCase.ocEmail2) {
        setAddOpposingCouncel2(!addOpposingCouncel2);
      }

      if (currentCase.ocName3 && currentCase.ocEmail3) {
        setAddOpposingCouncel3(!addOpposingCouncel3);
      }

      if (currentCase.ocName4 && currentCase.ocEmail4) {
        setAddOpposingCouncel4(!addOpposingCouncel4);
      }

      if (currentCase.ocName5 && currentCase.ocEmail5) {
        setAddOpposingCouncel5(!addOpposingCouncel5);
      }

      setCaseInfo(currentCase);
      currentCase.dueDate = moment(currentCase.dueDate);
      form.setFieldsValue(currentCase);

      if (currentCase.plaintiffOrDefendant == "plaintiff") {
        console.log("plaintiff");
      }

      if (currentCase.plaintiffOrDefendant == "defendant") {
        console.log("defendant");
      }

      let docsDefaults = [];
      currentCase.documents.map((doc) => {
        // console.log("doc type: ", doc.documentType);
        docsDefaults.push(doc.documentType);
      });

      // console.log("docsDefaults: ", docsDefaults);
      setDocsValues(docsDefaults);
      // console.log("after docsDefaults: ", docsDefaults);
    }
  }, [currentCase, setCaseInfo]);

  const clearForm = () => {
    setCaseInfo({
      matterNumber: "",
      caseName: "",
      plaintiffOrDefendant: "",
      plaintiff: "",
      defendant: "",
      court: "",
      case: "",
      ocName2: "",
      ocEmail1: "",
      ocName2: "",
      ocEmail2: "",
      ocName3: "",
      ocEmail3: "",
      ocName4: "",
      ocEmail4: "",
      ocName5: "",
      ocEmail5: "",
      contactInfoName: "",
      contactInfoEmail: "",
      dueDate: "",
      documents: [],
      client_id: "",
      attorney_id: "",
    });
  };

  const handleSubmit = () => {
    if (currentCase) {
      console.log("current case : ", caseInfo);
      if (caseInfo.matterNumber === "") {
        setFormError({
          ...formError,
          matterNumberErr: "Matter number is required",
        });
      } else if (caseInfo.caseName === "") {
        setFormError({
          ...formError,
          caseNameErr: "Case Name is required",
        });
      } else if (caseInfo.court === "") {
        setFormError({
          ...formError,
          courtErr: "Court Name is required",
        });
      } else if (caseInfo.caseNumber === "") {
        setFormError({
          ...formError,
          caseNumberErr: "Case Number is required",
        });
        // } else if (caseInfo.noticeHeading === "") {
        //   setFormError({
        //     ...formError,
        //     noticeHeadingErr: "Notice Heading is required",
        //   });
      }
      // else if (caseInfo.contactInfoName === "") {
      //   setFormError({
      //     ...formError,
      //     contactInfoNameErr: "Contact Name is required",
      //   });
      // } else if (caseInfo.contactInfoEmail === "") {
      //   setFormError({
      //     ...formError,
      //     contactInfoEmailErr: "Contact Email is required",
      //   });
      // }
      else {
        // console.log("formError: ", formError);

        dispatch(updateCase(currentCase._id, caseInfo));
        Modal.success({
          content: "Case has been updated successfully",
        });
        // alert("Case has been updated successfully");
        setCurrentId("");
        clearForm();
        // if (user.user.type === "admin-cas") {
        //   // navigate("/cases");
        //   window.location.replace("/cases");
        // } else if (user.user.type === "admin-att") {
        //   window.location.replace("/dashboard");
        // } else {
        //   // navigate("/");
        //   window.location.replace("/");
        // }

        if (accessControlHelper.VerifyAccess(accessControlHelper.SHOW_CASES_ON_DASHBOARD)) {
          window.location.replace("/dashboard");
        } else {
          window.location.replace("/cases");
        }
      }
    } else {
      // console.log("case info : ", caseInfo);

      if (caseInfo.plaintiffOrDefendant === "") {
        setFormError({
          ...formError,
          plaintiffOrDefendantErr: "Kindly select either of these two",
        });
      } else if (caseInfo.matterNumber === "") {
        setFormError({
          ...formError,
          matterNumberErr: "Matter number is required",
        });
      } else if (caseInfo.caseName === "") {
        setFormError({
          ...formError,
          caseNameErr: "Case Name is required",
        });
        // } else if (caseInfo.noticeHeading === "") {
        //   setFormError({
        //     ...formError,
        //     noticeHeadingErr: "Notice Heading is required",
        //   });
      } else {
        caseInfo.user_id = user.user.id;
        dispatch(createCase(caseInfo));
        clearForm();
        success();
        // navigate("/");
        Modal.success({
          content: "New case created successfully",
        });
        // alert("New case created successfully");
        window.location.replace("/");
      }
    }
  };

  const success = () => {
    Modal.success({
      content: "New case added",
    });
  };

  const addDocuments = (documentValues) => {
    const allDocuments = documentValues?.map((document) => {
      return { documentType: document, questions: [] };
    });
    setCaseInfo({
      ...caseInfo,
      documents: allDocuments,
    });
  };

  const onChange = (e) => {
    const { value, checked } = e.target;
    const { documents } = caseInfo;
    // console.log('documents' , 'ch', checked , '-', value)

    if (checked) {
      const newDocuments = [...documents, { documentType: value, questions: [] }]
      setCaseInfo({
        ...caseInfo,
        documents: newDocuments
      })

    }
    else {
      setCaseInfo({
        ...caseInfo,
        documents: documents?.filter((e) => e.documentType != value)
      })

    }
  }

  const options = [
    { label: "RFA", value: "rfa" },
    { label: "RFP", value: "rfp" },
    { label: "ROGGS", value: "roggs" },
  ];

  const values = options.map(item => item.value);


  const valueCheck = (val) => {
    let res = false;
    const resul = docsValues?.map((docs) => {
      if (docs == val) {
        res = true;
      }
    })
    return res;
  }

  const addDate = (dateString) => {
    setCaseInfo({ ...caseInfo, dueDate: dateString });
  };

  const onChangeHandler = (e) => {
    setCaseInfo({
      ...caseInfo,
      plaintiffOrDefendant: e.target.value,
    });

    setFormError({
      ...formError,
      plaintiffOrDefendantErr: "",
    });
  };

  return (
    <Card hoverable>
      <Typography.Title>
        {currentCase ? "Edit" : "New"} Discovery
      </Typography.Title>
      <Form
        layout="horizontal"
        onFinish={handleSubmit}
        onValuesChange={() => { }}
        form={form}
        className="discovery"
        initialValues={{ currentCase }}
      >
        <Form.Item name="ocType" label="">
          {currentCase && currentCase.plaintiffOrDefendant ? (
            <>
              {currentCase.plaintiffOrDefendant === "plaintiff" ? (
                <Radio.Group
                  optionType="button"
                  name="plaintiffORdefendant"
                  onChange={(e) => onChangeHandler(e)}
                  defaultValue="plaintiff"
                >
                  <Radio.Button value="plaintiff">Plaintiff</Radio.Button>
                  <Radio.Button value="defendant">Defendant</Radio.Button>
                </Radio.Group>
              ) : (
                <Radio.Group
                  optionType="button"
                  name="plaintiffORdefendant"
                  onChange={(e) => onChangeHandler(e)}
                  defaultValue="defendant"
                >
                  <Radio.Button value="plaintiff">Plaintiff</Radio.Button>
                  <Radio.Button value="defendant">Defendant</Radio.Button>
                </Radio.Group>
              )}
            </>
          ) : (
            <Radio.Group
              optionType="button"
              name="plaintiffORdefendant"
              onChange={(e) => onChangeHandler(e)}
            >
              <Radio.Button value="plaintiff">Plaintiff</Radio.Button>
              <Radio.Button value="defendant">Defendant</Radio.Button>
            </Radio.Group>
          )}
          {formError.plaintiffOrDefendantErr ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.plaintiffOrDefendantErr}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item>

        <Form.Item name="matterNumber">
          <div className="custom-form-label">Matter #</div>
          <Input
            value={caseInfo.matterNumber}
            onClick={() =>
              setFormError({
                ...formError,
                matterNumberErr: "",
              })
            }
            placeholder="Enter Matter Number"
            onChange={(e) =>
              setCaseInfo({ ...caseInfo, matterNumber: e.target.value })
            }
          />
          {formError.matterNumberErr ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.matterNumberErr}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item>

        <Form.Item name="caseName">
          <div className="custom-form-label">Case Name</div>
          <Input
            value={caseInfo.caseName}
            onClick={() =>
              setFormError({
                ...formError,
                caseNameErr: "",
              })
            }
            placeholder="Enter Case Name"
            onChange={(e) =>
              setCaseInfo({ ...caseInfo, caseName: e.target.value })
            }
          />
          {formError.caseNameErr ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.caseNameErr}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item>

        <Form.Item name="Name">
          <div className="custom-form-label">Plaintiff</div>
          <Input
            value={caseInfo.plaintiff}
            onClick={() =>
              setFormError({
                ...formError,
                plaintiffErr: "",
              })
            }
            placeholder="Enter Plaintiff Name"
            onChange={(e) =>
              setCaseInfo({ ...caseInfo, plaintiff: e.target.value })
            }
          />
          {formError.plaintiff ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.plaintiff}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item>

        <Form.Item name="Name">
          <div className="custom-form-label">Defendant</div>
          <Input
            value={caseInfo.defendant}
            onClick={() =>
              setFormError({
                ...formError,
                defendantErr: "",
              })
            }
            placeholder="Enter Defendant Name"
            onChange={(e) =>
              setCaseInfo({ ...caseInfo, defendant: e.target.value })
            }
          />
          {formError.defendantErr ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.defendantErr}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item>

        <Form.Item name="court">
          <div className="custom-form-label">Court Name</div>
          <Input
            value={caseInfo.court}
            onClick={() =>
              setFormError({
                ...formError,
                courtErr: "",
              })
            }
            placeholder="Enter Court Name"
            onChange={(e) =>
              setCaseInfo({ ...caseInfo, court: e.target.value })
            }
          />
          {formError.courtErr ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.courtErr}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item>

        <Form.Item name="caseNumber">
          <div className="custom-form-label">Case Number</div>
          <Input
            value={caseInfo.caseNumber}
            onClick={() =>
              setFormError({
                ...formError,
                caseNumberErr: "",
              })
            }
            placeholder="Enter Case Number"
            onChange={(e) =>
              setCaseInfo({ ...caseInfo, caseNumber: e.target.value })
            }
          />
          {formError.caseNumberErr ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.caseNumberErr}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item>

        {/* <Form.Item name="noticeHeading">
          <div className="custom-form-label">Notice Heading</div>
          <Input
            value={caseInfo.test}
            onClick={() =>
              setFormError({
                ...formError,
                noticeHeadingErr: "",
              })
            }
            placeholder="Enter Notice Heading"
            onChange={(e) =>
              setCaseInfo({ ...caseInfo, noticeHeading: e.target.value })
            }
          />
          {formError.noticeHeadingErr ? (
            <>
              <div>
                <p style={{ marginTop: 2, color: "red" }}>
                  {formError.noticeHeadingErr}
                </p>
              </div>
            </>
          ) : null}
        </Form.Item> */}



        <Form.Item name="caseNumber">
          <div className="custom-form-label">Opposing Counsel</div>
          {currentCase ? (
            <div style={{ width: "20%", textAlign: "left", marginBottom: 10 }}>
              {
                // currentCase &&
                // currentCase.ocName5 &&
                // currentCase.ocEmail5 ? null : currentCase &&
                //   currentCase.ocName4 &&
                //   currentCase.ocEmail4 ? null : currentCase &&
                //   currentCase.ocName3 &&
                //   currentCase.ocEmail3 ? null : currentCase &&
                //   currentCase.ocName2 &&
                //   currentCase.ocEmail2 ? null :
                addOpposingCouncel1 ||
                  (currentCase &&
                    currentCase.ocName1 &&
                    currentCase.ocEmail1) ? null : (
                  <Button
                    type="dashed"
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 5,
                      paddingRight: 25,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setAddOpposingCouncel1(!addOpposingCouncel1);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                )
              }
            </div>
          ) : (
            <div style={{ width: "20%", textAlign: "left", marginBottom: 10 }}>
              {addOpposingCouncel5 ? null : addOpposingCouncel4 ? null : addOpposingCouncel3 ? null : addOpposingCouncel2 ? null : addOpposingCouncel1 ? null : (
                <Button
                  type="dashed"
                  style={{
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingLeft: 5,
                    paddingRight: 25,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    setAddOpposingCouncel1(!addOpposingCouncel1);
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Add
                </Button>
              )}
            </div>
          )}

          {currentCase &&
            addOpposingCouncel1 &&
            currentCase.ocName1 &&
            currentCase.ocEmail1 ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <Input
                  placeholder="Name"
                  value={currentCase.ocName1}
                  onChange={(e) =>
                    setCaseInfo({
                      ...caseInfo,
                      ocName1: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ width: "100%" }}>
                <Input
                  placeholder="Email"
                  style={{ marginLeft: 10 }}
                  value={currentCase.ocEmail1}
                  onChange={(e) =>
                    setCaseInfo({
                      ...caseInfo,
                      ocEmail1: e.target.value,
                    })
                  }
                />
              </div>
              {addOpposingCouncel2 ? null : (
                <div style={{ marginLeft: 20 }}>
                  <Button
                    type="dashed"
                    style={{
                      paddingTop: 10,
                      paddingBottom: 10,
                      paddingLeft: 5,
                      paddingRight: 25,
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      setAddOpposingCouncel2(!addOpposingCouncel2);
                    }}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add
                  </Button>
                </div>
              )}
              <div style={{ marginLeft: 20 }}>
                <Button
                  type="dashed"
                  style={{ padding: '12px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setAddOpposingCouncel1(!addOpposingCouncel1);
                    if (currentCase) {
                      if (currentCase.ocName1) {
                        currentCase.ocName1 = "";
                      }

                      if (currentCase.ocEmail1) {
                        currentCase.ocEmail1 = "";
                      }
                    }
                  }}
                  block
                  icon={<MinusOutlined />}
                >
                  Remove
                </Button>
              </div>
            </div>
          ) : (
            <>
              {addOpposingCouncel1 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName1: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      style={{ marginLeft: 10 }}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail1: e.target.value,
                        })
                      }
                    />
                  </div>
                  {addOpposingCouncel2 ? null : (
                    <>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel2(!addOpposingCouncel2);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add
                        </Button>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel1(!addOpposingCouncel1);
                          }}
                          block
                          icon={<MinusOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </>
          )}

          {currentCase && currentCase.ocName2 && currentCase.ocEmail2 ? (
            <>
              {addOpposingCouncel2 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      value={currentCase.ocName2}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      value={currentCase.ocEmail2}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail2: e.target.value,
                        })
                      }
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  {addOpposingCouncel3 ? null : (
                    <div style={{ marginLeft: 20 }}>
                      <Button
                        type="dashed"
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 5,
                          paddingRight: 25,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpposingCouncel3(!addOpposingCouncel3);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </div>
                  )}

                  <div style={{ marginLeft: 20 }}>
                    <Button
                      type="dashed"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 5,
                        paddingRight: 25,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAddOpposingCouncel2(!addOpposingCouncel2);
                        if (currentCase) {
                          if (currentCase.ocName2) {
                            currentCase.ocName2 = "";
                          }

                          if (currentCase.ocEmail2) {
                            currentCase.ocEmail2 = "";
                          }
                        }
                      }}
                      block
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {addOpposingCouncel2 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName2: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail2: e.target.value,
                        })
                      }
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  {addOpposingCouncel3 ? null : (
                    <>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel3(!addOpposingCouncel3);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add
                        </Button>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel2(!addOpposingCouncel2);
                          }}
                          block
                          icon={<MinusOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </>
          )}

          {currentCase && currentCase.ocName3 && currentCase.ocEmail3 ? (
            <>
              {addOpposingCouncel3 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      value={currentCase.ocName3}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      style={{ marginLeft: 10 }}
                      value={currentCase.ocEmail3}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail3: e.target.value,
                        })
                      }
                    />
                  </div>
                  {addOpposingCouncel4 ? null : (
                    <div style={{ marginLeft: 20 }}>
                      <Button
                        type="dashed"
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 5,
                          paddingRight: 25,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpposingCouncel4(!addOpposingCouncel4);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                  <div style={{ marginLeft: 20 }}>
                    <Button
                      type="dashed"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 5,
                        paddingRight: 25,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAddOpposingCouncel3(!addOpposingCouncel3);
                        if (currentCase) {
                          if (currentCase.ocName3) {
                            currentCase.ocName3 = "";
                          }

                          if (currentCase.ocEmail3) {
                            currentCase.ocEmail3 = "";
                          }
                        }
                      }}
                      block
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {addOpposingCouncel3 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName3: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      style={{ marginLeft: 10 }}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail3: e.target.value,
                        })
                      }
                    />
                  </div>
                  {addOpposingCouncel4 ? null : (
                    <>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel4(!addOpposingCouncel4);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add
                        </Button>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel3(!addOpposingCouncel3);
                          }}
                          block
                          icon={<MinusOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </>
          )}

          {currentCase && currentCase.ocName4 && currentCase.ocEmail4 ? (
            <>
              {addOpposingCouncel4 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      value={currentCase.ocName4}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName4: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      value={currentCase.ocEmail4}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail4: e.target.value,
                        })
                      }
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  {addOpposingCouncel5 ? null : (
                    <div style={{ marginLeft: 20 }}>
                      <Button
                        type="dashed"
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 5,
                          paddingRight: 25,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setAddOpposingCouncel5(!addOpposingCouncel5);
                        }}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add
                      </Button>
                    </div>
                  )}
                  <div style={{ marginLeft: 20 }}>
                    <Button
                      type="dashed"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 5,
                        paddingRight: 25,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAddOpposingCouncel4(!addOpposingCouncel4);
                        if (currentCase) {
                          if (currentCase.ocName4) {
                            currentCase.ocName4 = "";
                          }

                          if (currentCase.ocEmail4) {
                            currentCase.ocEmail4 = "";
                          }
                        }
                      }}
                      block
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {addOpposingCouncel4 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName4: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail4: e.target.value,
                        })
                      }
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  {addOpposingCouncel5 ? null : (
                    <>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel5(!addOpposingCouncel5);
                          }}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add
                        </Button>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <Button
                          type="dashed"
                          className={classes.addRemoveButton}
                          onClick={(e) => {
                            e.preventDefault();
                            setAddOpposingCouncel4(!addOpposingCouncel4);
                          }}
                          block
                          icon={<MinusOutlined />}
                        >
                          Remove
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </>
          )}

          {currentCase && currentCase.ocName5 && currentCase.ocEmail5 ? (
            <>
              {addOpposingCouncel5 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      value={currentCase.ocName5}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName5: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      value={currentCase.ocEmail5}
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail5: e.target.value,
                        })
                      }
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <Button
                      type="dashed"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 5,
                        paddingRight: 25,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAddOpposingCouncel5(!addOpposingCouncel5);
                        if (currentCase) {
                          if (currentCase.ocName5) {
                            currentCase.ocName5 = "";
                          }

                          if (currentCase.ocEmail5) {
                            currentCase.ocEmail5 = "";
                          }
                        }
                      }}
                      block
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {addOpposingCouncel5 ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Name"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocName5: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <Input
                      placeholder="Email"
                      onChange={(e) =>
                        setCaseInfo({
                          ...caseInfo,
                          ocEmail5: e.target.value,
                        })
                      }
                      style={{ marginLeft: 10 }}
                    />
                  </div>
                  <div style={{ marginLeft: 20 }}>
                    <Button
                      type="dashed"
                      style={{
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 5,
                        paddingRight: 25,
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setAddOpposingCouncel5(!addOpposingCouncel5);
                      }}
                      block
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : null}
            </>
          )}
        </Form.Item>

        <Form.Item className="contact">
          <div className="custom-form-label">Contact Info</div>
          <Space style={{ display: "flex" }}>
            <Form.Item name="contactInfoName">
              <Input
                placeholder="Name"
                value={caseInfo.contactInfoName}
                onClick={() =>
                  setFormError({
                    ...formError,
                    contactInfoNameErr: "",
                  })
                }
                onChange={(e) =>
                  setCaseInfo({ ...caseInfo, contactInfoName: e.target.value })
                }
              />
              {formError.contactInfoNameErr ? (
                <>
                  <div>
                    <p style={{ marginTop: 2, color: "red" }}>
                      {formError.contactInfoNameErr}
                    </p>
                  </div>
                </>
              ) : null}
            </Form.Item>
            <Form.Item name="contactInfoEmail">
              <Input
                placeholder="Email"
                value={caseInfo.contactInfoEmail}
                onClick={() =>
                  setFormError({
                    ...formError,
                    contactInfoEmailErr: "",
                  })
                }
                onChange={(e) =>
                  setCaseInfo({ ...caseInfo, contactInfoEmail: e.target.value })
                }
              />
              {formError.contactInfoEmailErr ? (
                <>
                  <div>
                    <p style={{ marginTop: 2, color: "red" }}>
                      {formError.contactInfoEmailErr}
                    </p>
                  </div>
                </>
              ) : null}
            </Form.Item>
          </Space>
        </Form.Item>

        <Form.Item name="dueDate" className="date" >
          <div className="custom-form-label">Due Date</div>
          <DatePicker
            className={classes.datePicker}
            placeholder="Select Due Date"
            onChange={addDate}
            format="MM/DD/YYYY"
          />
        </Form.Item>
        {user.user.role === accessControlHelper.SUPER_ADMIN_ROLE ||
          user.user.role === accessControlHelper.CASE_ADMIN_ROLE ||
          user.user.role === accessControlHelper.ATTORNEY_ROLE ? (
          <Form.Item name="client_id">
            <div className="custom-form-label">Client</div>
            <Select
              onChange={(e) => setCaseInfo({ ...caseInfo, client_id: e })}
            >
              {clientChildren}
            </Select>
          </Form.Item>
        ) : <></>}
        {/* {user.user.type === "admin-sup" || user.user.type === "admin-cas" && (
          <Form.Item name="attorney_id" label="Attorney">
              <Select  onChange={(e) =>
                    setCaseInfo({ ...caseInfo, attorney_id: e })
                  }>
                    {adminChildren}
              </Select>
          </Form.Item>
        ) } */}
        {user.user.role === accessControlHelper.SUPER_ADMIN_ROLE ||
          user.user.role === accessControlHelper.CASE_ADMIN_ROLE ||
          user.user.role === accessControlHelper.ATTORNEY_ROLE ? (
          <Form.Item>
            <div className="custom-form-label">Which Documents?</div>
            {currentCase && Object.keys(currentCase).length > 0 ? (
              <div className='Check' >
                {docsValues ? <> <div className='Check'>
                  <div><input type="checkbox" defaultChecked={valueCheck('rfa')} id='rfa' name='rfa' value='rfa' onChange={onChange} /></div>
                  <div><label htmlFor='rfa'>RFA</label></div>
                </div>
                  <div className='Check' >
                    <div><input type="checkbox" defaultChecked={valueCheck('rfp')} id='rfp' name='rfp' value='rfp' onChange={onChange} /></div>
                    <div><label htmlFor='rfp'>RFP</label></div>
                  </div>
                  <div className='Check' >
                    <div><input type="checkbox" defaultChecked={valueCheck('roggs')} id='roggs' name='roggs' value='roggs' onChange={onChange} /></div>
                    <div><label htmlFor='roggs'>ROGGS</label></div>
                  </div>
                </> : null}
              </div>
            ) : (
              <Checkbox.Group options={options} onChange={addDocuments} />
            )}
          </Form.Item>
        ) : null}

        <Form.Item name="submit-button">
          <div style={{ marginTop: 10, textAlign: "right", width: "80%" }}>
            <Button htmlType="submit" type="primary">
              {currentCase ? "Update" : "Add New Case"}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddNewCases;

import { message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const { Dragger } = Upload;

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

const extractTextFromPDF = async (pdfData) => {
  try {
    const loadingTask = pdfjs.getDocument(pdfData);
    const pdfDocument = await loadingTask.promise;
    const numPages = pdfDocument.numPages;
    let pdfText = "";

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdfDocument.getPage(pageNum);
      const pageText = await page.getTextContent();
      const pageStrings = pageText.items.map((item) => item.str);
      pdfText += pageStrings.join(" "); // Concatenate text of the current page
    }

    return pdfText;
  } catch (error) {
    throw error;
  }
};

const UploadQuestions = ({ setDocument, generateQuestions }) => {

  const uploadProps = {
    name: 'file',
    accept : ".pdf",
    multiple: true,
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
        const reader = new FileReader();

        reader.onload = async (event) => {
          const pdfData = event.target.result;
          try {
            const extractedText = await extractTextFromPDF(pdfData);
            setDocument(extractedText, generateQuestions)
          } catch (error) {
            console.error("Error extracting text:", error);
          }
        };

        reader.readAsArrayBuffer(info.file.originFileObj);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return(
    <div>
      <Dragger 
        {...uploadProps}
        customRequest={dummyRequest}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload.
      </p>
    </Dragger>
    </div>
  )
}

export default UploadQuestions;
import { useEffect, useState } from "react";
import { registerUser } from "../../actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { Form, Input, Button, Typography, Card, Alert, Select } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { fetchAllUsers, updateUser } from "../../actions/users";
import { fetchRoles } from "../../actions/accessControl";
import axios from "axios";
import * as accessControlHelper from '../../utils/accessControlHelper';

const { Option } = Select;

const NewUser = () => {
  const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'
  const userCurrent = useSelector((state) => state.auth);
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    password2: "",
    type: "",
    role_id: "",
    att_limit: 1,
    errors: {},
  });

  const [form] = Form.useForm();

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // console.log(userCurrent?);
  const errors = useSelector((state) => state.errors);

  useEffect(() => {
    dispatch(fetchAllUsers(userCurrent));
  }, [dispatch]);

  const users = useSelector((state) => state.users);
  const firms = users?.filter((x) => {
    return x.role_id.slug === accessControlHelper.ATTORNEY_ROLE;
  });

  const currentUser = id ? users.find((item) => item._id === id) : null;
  useEffect(() => {
    if (currentUser) {
      currentUser.password = "";
      setUser(currentUser);
      form.setFieldsValue(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (errors) {
      setUser((prevState) => ({ ...prevState, errors: errors }));
    }
  }, [errors]);

  const [roles, setRoles] = useState([]);
  if (roles.length) {
    var super_admin_role_id = roles.filter((role) => role.slug == accessControlHelper.SUPER_ADMIN_ROLE)[0]._id;
    var case_admin_role_id = roles.filter((role) => role.slug == accessControlHelper.CASE_ADMIN_ROLE)[0]._id;
    var attorney_role_id = roles.filter((role) => role.slug == accessControlHelper.ATTORNEY_ROLE)[0]._id;
    var client_role_id = roles.filter((role) => role.slug == accessControlHelper.CLIENT_ROLE)[0]._id;
  }

  // console.log("super_admin_role_id", super_admin_role_id);
  // console.log("case_admin_role_id", case_admin_role_id);
  // console.log("attorney_role_id", attorney_role_id);
  // console.log("client_role_id", client_role_id);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/access-control/role`)
      .then((res) => setRoles(res.data.role))
      .catch((error) => console.log(error))

  }, [roles.length])


  const onChange = (e) => {
    if (typeof e === "object") {
      e.persist();
      setUser((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
    } else {
      setUser((prevState) => ({ ...prevState, type: e }));
    }
  };

  const onRoleChange = (e) => {
    if (typeof e === "object") {
      e.persist();
      setUser((prevState) => ({ ...prevState, [e.target.id]: e.target.value }));
    } else {
      setUser((prevState) => ({ ...prevState, role_id: e }));
    }
  };

  const onSubmit = () => {
    if (currentUser) {
      if (user.password === "") {
        const newUser = {
          name: user.name,
          email: user.email,
          type: user.type,
          role_id: user.role_id,
        };
        if (newUser.role_id == case_admin_role_id) {
          newUser.att_limit = user.att_limit;
        }
        dispatch(updateUser(currentUser._id, newUser));
      } else {
        const newUser = {
          name: user.name,
          email: user.email,
          password: user.password,
          password2: user.password2,
          type: user.type,
          role_id: user.role_id,
        };
        if (newUser.role_id === case_admin_role_id) {
          newUser.att_limit = user.att_limit;
        }
        dispatch(updateUser(currentUser._id, newUser));
      }
      navigate("/users");
    } else {
      if (user.password === "") {
        const newUser = {
          name: user.name,
          email: user.email,
          password: "",
          password2: "",
          type: user.type,
          role_id: user.role_id,
          user_id: userCurrent?.user.id,
        };
        if (newUser.role_id === case_admin_role_id) {
          newUser.att_limit = user.att_limit;
        }
        dispatch(registerUser(newUser));
        if (errors) {
          console.log(errors);
          return;
        } else {
          navigate("/users");
        }
      } else {
        const newUser = {
          name: user.name,
          email: user.email,
          password: user.password,
          password2: user.password2,
          type: user.type,
          role_id: user.role_id,
          user_id: userCurrent?.user.id,
        };
        dispatch(registerUser(newUser));
        if (errors) {
          console.log(errors);
          return;
        } else {
          navigate("/users");
        }
      }
    }
  };

  const clearForm = () => {
    setUser({
      name: "",
      email: "",
      password: "",
      password2: "",
      type: "",
      role_id: "",
      att_limit: 1,
      errors: {},
    })
  }

  return (
    <Card hoverable>
      <Form
        layout="vertical"
        onFinish={onSubmit}
        onValuesChange={() => { }}
        form={form}
      >
        <Typography.Title>
          {currentUser ? "Update User" : "New User"}
        </Typography.Title>
        {/* {userCurrent?.user.role_id === case_admin_role_id &&
            userCurrent?.user.att_limit == firms.length &&
            user.role_id == attorney_role_id ? (
            <Alert
              message="You have reached your limit to add new Attorney"
              type="error"
            />
          ) : (
            ""
          )} */}
        <Form.Item name="name" label="Name">
          {errors.name && user.errors.name && <Alert message={user.errors.name} type="error" />}
          <Input
            onChange={onChange}
            value={user.name}
            error={errors.name}
            id="name"
            type="text"
            disabled={
              userCurrent?.user.role_id === case_admin_role_id &&
                userCurrent?.user.att_limit == firms.length &&
                user.role_id == attorney_role_id
                ? true
                : false
            }
            className={classnames("", {
              invalid: errors.name,
            })}
          />
        </Form.Item>
        <Form.Item name="email" label="Email">
          {errors.email && user.errors.email && <Alert message={user.errors.email} type="error" />}
          <Input
            onChange={onChange}
            value={user.email}
            error={errors.email}
            id="email"
            type="email"
            disabled={currentUser ? true : false}
            className={classnames("", {
              invalid: errors.email,
            })}
          />
        </Form.Item>
        {(userCurrent?.role_id === super_admin_role_id &&
          currentUser?.role_id === super_admin_role_id) ||
          user.role_id == super_admin_role_id ? (
          <Form.Item name="password" label="Password">
            {errors.password && user.errors.password && <Alert message={user.errors.password} type="error" />}
            <Input
              onChange={onChange}
              value={user.password}
              error={errors.password}
              id="password"
              type="password"
              disabled={
                userCurrent?.user.role_id === case_admin_role_id &&
                  userCurrent?.user.att_limit == firms.length &&
                  user.role_id == attorney_role_id
                  ? true
                  : false
              }
              className={classnames("", {
                invalid: errors.password,
              })}
            />
          </Form.Item>
        ) : (
          ""
        )}
        {(userCurrent?.user.role_id === case_admin_role_id &&
          currentUser?.user.role_id === case_admin_role_id) ||
          user.role_id === case_admin_role_id ? (
          <Form.Item name="att_limit" label="Attorney Limit">
            {errors.att_limit && user.errors.att_limit && <Alert message={user.errors.att_limit} type="error" />}
            <Input
              onChange={onChange}
              value={user.att_limit}
              error={errors.att_limit}
              id="att_limit"
              type="number"
              disabled={
                userCurrent?.user.role_id === case_admin_role_id &&
                  userCurrent?.user.att_limit == firms.length &&
                  user.role_id == attorney_role_id
                  ? true
                  : false
              }
              required
              className={classnames("", {
                invalid: errors.att_limit,
              })}
            />
          </Form.Item>
        ) : (
          ""
        )}
        {(userCurrent?.role_id === super_admin_role_id &&
          currentUser?.role_id === super_admin_role_id) ||
          user.role_id === super_admin_role_id ? (
          <Form.Item name="password2" label="Confirm Password">
            {errors.password2 && user.errors.password2 && <Alert message={user.errors.password2} type="error" />}
            <Input
              onChange={onChange}
              value={user.password2}
              error={errors.password2}
              id="password2"
              type="password"
              disabled={
                userCurrent?.role_id === case_admin_role_id &&
                  userCurrent?.user.att_limit == firms.length &&
                  user.role_id === attorney_role_id
                  ? true
                  : false
              }
              className={classnames("", {
                invalid: errors.password2,
              })}
            />
          </Form.Item>
        ) : (
          ""
        )}
        {/* <Form.Item name="type" label="Select Type">
            {errors.type && user.errors.type && <Alert message={user.errors.type} type="error" />}
            <Select
              placeholder="Select Type"
              onChange={onChange}
              value={user.type}
              error={errors.type}
              id="type"
              className={classnames("", {
                invalid: errors.type,
              })}
              defaultValue="client"
            >
              {userCurrent?.user.type === "admin-cas" ||
                userCurrent?.user.type === "admin-att" ? (
                <Option value="client">Client</Option>
              ) : null}
              {
                userCurrent?.user.type === "admin-cas" && (
                  // ||
                  // userCurrent?.user.type === "admin-sup" ?
                  <Option value="admin-att">Attorney</Option>
                )
                // : null
              }
              {userCurrent?.user.type === "admin-sup" && (
                <Option value="admin-cas">Law Firm</Option>
              )}
              {userCurrent?.user.type === "admin-sup" && (
                <Option value="admin-sup">Super Admin</Option>
              )}
            </Select>
          </Form.Item> */}

        <Form.Item name="role_id" label="Select Role">
          {errors.role_id && user.errors.role_id && <Alert message={user.errors.role_id} type="error" />}
          <Select
            placeholder="Select Role"
            onChange={onRoleChange}
            // value={user.role_id}
            error={errors.role_id}
            id="role_id"
            className={classnames("", {
              invalid: errors.role_id,
            })}
          >
            {
              roles.map((role, i) => (
                <Option key={role._id} selected={i == 2 ? true : false} value={role._id}>{role.name}</Option>
              ))
            }
          </Select>
        </Form.Item>

      </Form>
      <Button
        style={{ marginRight: '1rem' }}
        className="btn btn-success"
        onClick={clearForm}
      >
        {'Clear Form'}
      </Button>
      <Button
        onClick={onSubmit}
        className="btn btn-success"
        disabled={
          userCurrent?.user.role_id === case_admin_role_id &&
            userCurrent?.user.att_limit == firms.length &&
            user.role_id === attorney_role_id
            ? true
            : false
        }
      >
        {currentUser ? "Save Changes" : "Add New User"}
      </Button>
    </Card>

  );
};
export default NewUser;


import { useState } from "react";
import { Card, Typography, Space, Button } from "antd";
import { useParams } from "react-router-dom";
import { PlusSquareOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import ObjectionsResponsesSelector from "./ObjectionsResponsesSelector";
import DrawerForm from "./Drawer.js";
import * as accessControlHelper from '../../utils/accessControlHelper';
import EditibleItem from "../Document/EditibleItem/EditibleItem.js";

const Questions = ({ questions, documentQ }) => {
  const { document } = useParams();
  const [comentAble, setCommentAble] = useState(false);
  const [commentAbleQuestion, setCommentAbleQuestion] = useState({});
  const user = useSelector((state) => state.auth);

  const comment = (question) => {
    setCommentAble(true)
    setCommentAbleQuestion(question);
  };

  const drawerState = (st) => {
    setCommentAble(st);
  }

  // console.log("user: ", user);
  return (
    <>
      {questions.length !== 0 && Object.keys(user).length > 0 &&
        questions.map((question) => {
          const { _id, info, response } = question;
          console.log("question: ", question)
          return (
            <Card style={{ marginBottom: '1rem' }} key={_id} >
              <Space size="small" direction="vertical" style={{ width: "90%" }}>
                <Typography>{info}</Typography>
                {
                  user.user.role === accessControlHelper.CLIENT_ROLE ? null :
                    // <ObjectionsResponsesSelector
                    //   document={document}
                    //   question={question}
                    //   id={_id}
                    // />
                    <EditibleItem 
                    item={question} 
                    document={document} 
                    response={response} 
                    question={info}
                     id={_id} />

                }
              </Space>
              {question.isReviewable != undefined && question.isReviewable == true ? <Button
                type="primary"
                icon={<PlusSquareOutlined />}
                onClick={() => comment(question)}
                style={{
                  float: "right",
                  marginBottom: 20,
                  transform: "translateY(100%)"
                }}
              >Comment</Button> : null}
            </Card>
          );
        })}
      {comentAble ?
        <DrawerForm question={commentAbleQuestion} document={documentQ} onchange={() => drawerState()} /> :
        null
      }
    </>
  );
};

export default Questions;

export default (cases = [], action) => {
  switch (action.type) {
    case "FETCH_ALL":
      return action.payload;
    case "CREATE":
      return [...cases, action.payload];
    case "UPDATE":
      return cases.map((caseInfo) =>
        caseInfo._id === action.payload._id ? action.payload : caseInfo
      );
    case "UPDATE_CASE_DOCS_NAMES":
      return cases.map((id, caseInfo) =>
        caseInfo._id === action.payload._id ? action.payload : caseInfo
      );
    case "DELETE":
      return cases.filter((caseInfo) => caseInfo._id !== action.payload);
    // case "UPDATE_CASE_HEADINGS":
    //   return cases.filter((caseInfo) => caseInfo._id !== action.payload);
    default:
      return cases;
  }
};

export default (comments = [], action) => {
    switch (action.type) {
              case "GET_ALL_COMMENTS":
                      return action.payload;
              case "CREATE_COMMENT":
                      return [...comments, action.payload];
                case "UPDATE_USER_AUTH":
                  return {
                          isEnabled: action.payload
                  };
              default:
                        return comments;
    }
};
  
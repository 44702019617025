
import { useDispatch, useSelector } from "react-redux";
import { Table, Space, Button, Card } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { useGlobalContext } from "../../App";
import { useEffect, useState } from "react";
import axios from "axios";
const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://app.litigationtec.com' : 'http://localhost:5000'

const RolesList = () => {
    const [roles, setRoles] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}/api/access-control/role`)
            .then((res) => setRoles(res.data.role))
            .catch((error) => console.log(error))

        console.log(roles)
    }, [roles.length])


    const columns = [
        {
            title: "Role",
            dataIndex: "role",
            key: "role",
            render: (text, record) => <Link to={`/access-control/new/${record._id}`}>{text}</Link>,
        },

        {
            title: "Action",
            key: "actions",
            dataIndex: "_id",
            render: (_id) => (
                <Space size="middle">
                    <Link to={`/access-control/new/${_id}`}>
                        <EditOutlined />
                    </Link>
                </Space>
            ),
        },
    ];

    const tableData = Array.isArray(roles)
        ? roles.map((role) => {
            const { name, _id } = role;
            return {
                key: _id,
                "role": name,
                _id,
            };
        })
        : "";

    return (
        <>
            <Card hoverable>
                <div>
                    <div
                        style={{
                            marginBottom: 20,
                            display: "flex",
                            justifyContent: "flex-end",
                        }}
                    >
                        <Link to={"/access-control/new"}>
                            <Button type="primary">Add New Role</Button>
                        </Link>
                    </div>
                    <div>
                        <Table columns={columns} dataSource={tableData} />
                    </div>
                </div>
            </Card>
        </>
    );
};

export default RolesList;

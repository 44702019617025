import { combineReducers } from "redux";

import cases from "./cases";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import users from "./users";
import comments from "./comments";
import roles from "./roles";
import permissions from "./permissions";

export default combineReducers({
  cases: cases,
  auth: authReducer,
  errors: errorReducer,
  users: users,
  user_permissions: users,
  comments: comments,
  roles: roles,
  permissions: permissions,
});


import { Wrapper } from "./style";
import Header from "./Header/Header";
import EditibleItem from "./EditibleItem/EditibleItem";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCaseHeadings } from "../../actions/cases";
import { Button, Form, Input, Tooltip } from "antd";

const Documents = ({ user, document, list, caseData }) => {

  const preferred_document_font = user?.document_font ?? "arial";
  const dispatch = useDispatch();
  const initiaRequestHeading =
    document === "rfa"
      ? "PLAINTIFF'S RESPONSE TO DEFENDANT'S REQUEST FOR ADMISSIONS"
      : document === "rfp"
        ? "PLAINTIFF'S RESPONSE TO DEFENDANT'S REQUEST FOR PRODUCTION"
        : "PLAINTIFF'S RESPONSE TO DEFENDANT'S SET OF INTERROGATORIES";

  const [requestHeading, setRequestHeading] = useState(initiaRequestHeading)
  const [editRequestHeading, setEditRequestHeading] = useState(false)
  const [updatedRequestHeading, setUpdatedRequestHeading] = useState()

  useEffect(() => {
    if (caseData) {
      const currentDocument = caseData.documents.filter(
        (item) => item.documentType === document
      )[0];
      setRequestHeading(currentDocument.requestHeading ?? initiaRequestHeading);
    }
  }, [caseData]);


  const toggleEditRequestHeading = (() => {
    let state = !editRequestHeading
    setEditRequestHeading(state);
  })

  const handleUpdateRequestHeading = async () => {
    if (updatedRequestHeading != "" && caseData) {
      const currentDocument = caseData.documents.filter(
        (item) => item.documentType === document
      )[0];

      await dispatch(updateCaseHeadings(caseData._id, currentDocument._id, {
        type: "requestHeading",
        data: updatedRequestHeading
      }));
      toggleEditRequestHeading()
      setRequestHeading(updatedRequestHeading)
    }
  }
  return (
    <Wrapper>
      <Header user={user} caseData={caseData} document={document} />
      <div style={{ pageBreakBefore: "always" }}>
        {list.length > 0 && (
          <>
            {!editRequestHeading ?
              <Tooltip onClick={toggleEditRequestHeading} title={!editRequestHeading ? 'Click to edit' : ''} placement="topLeft">
                <strong>
                  <u>
                    <p style={{ textTransform: "uppercase", textAlign: "center", fontFamily: preferred_document_font }}>
                      {requestHeading}
                    </p>
                  </u>
                </strong>
              </Tooltip> :
              <div>
                <Form.Item name="requestHeading">
                  <div className="custom-form-label"></div>
                  <Input
                    value={updatedRequestHeading ? updatedRequestHeading : requestHeading}
                    placeholder="Enter Request Heading"
                    onChange={(e) =>
                      setUpdatedRequestHeading(e.target.value)
                    }
                  />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: 'end', marginBottom: "10px" }}>
                  <Button onClick={toggleEditRequestHeading} type='primary'> Cancel</Button>
                  <Button onClick={handleUpdateRequestHeading} style={{ marginLeft: '0.75rem' }} type='primary'>Save</Button>
                </div>
              </div>
            }
          </>
        )}


        {/* <ul> */}
        {list.map((item) => {
          const { info, response, _id } = item;
          return (
            <article key={_id}>
              <span style={{ fontFamily: preferred_document_font }}>{info}</span>
              <EditibleItem item={item} document={document} response={response} question={info} id={_id} />
            </article>
          );
        })}
        {/* </ul> */}
      </div>
    </Wrapper>
  );
};

export default Documents;



import QuestionList from "../../components/QuestionList/QuestionList";

const AddResponse = () => {
  return (
    <>
      <QuestionList />
    </>
  );
};

export default AddResponse;
